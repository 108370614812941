import { AuthRole } from "./Enums";

export const ROUTE = {
  ROOT: {
    PATH: "/",
    ROLES: [],
  },
  DASHBOARD: {
    PATH: "/dashboard",
    ROLES: [AuthRole.ADMIN, AuthRole.TEACHER],
  },
  SCHOOLS: {
    PATH: "/schools",
    ROLES: [AuthRole.ADMIN],
  },
  TEACHERS: {
    PATH: "/teachers",
    ROLES: [AuthRole.ADMIN],
  },
  STUDENTS: {
    PATH: "/students",
    ROLES: [AuthRole.ADMIN, AuthRole.TEACHER],
  },
  QUESTION_TEXT: {
    PATH: "/question-text",
    ROLES: [AuthRole.ADMIN],
  },
  QUESTION_IMAGE: {
    PATH: "/question-image",
    ROLES: [AuthRole.ADMIN],
  },
  QUESTIONS: {
    PATH: "/questions",
    ROLES: [AuthRole.ADMIN],
  },
  QUESTION_CATEGORIES: {
    PATH: "/question-categories",
    ROLES: [AuthRole.ADMIN],
  },
  QUESTION_DIFFICULTY: {
    PATH: "/question-difficulty",
    ROLES: [AuthRole.ADMIN],
  },
  TESTS_STATS: {
    PATH: "/tests-stats",
    ROLES: [AuthRole.ADMIN],
  },

  // Stats

  SCHOOLS_STATS: {
    PATH: "/schools-stats",
    ROLES: [AuthRole.ADMIN, AuthRole.TEACHER],
  },
  STUDENTS_STATS: {
    PATH: "/students-stats",
    ROLES: [AuthRole.ADMIN, AuthRole.TEACHER],
  },
  QUESTION_STATS: {
    PATH: "/question-stats",
    ROLES: [AuthRole.ADMIN],
  },
};

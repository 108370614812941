import { ApolloError, useMutation } from "@apollo/client";
import Papa, { ParseResult } from "papaparse";
import { logError, logInfo } from "../../utils/Logger";
import { Button } from "@mui/material";
import { CREATE_STUDENT } from "../../graphql/Students";
import { ChangeEvent } from "react";
import { FormattedMessage } from "react-intl";

export const StudentsCsvImportButton = () => {
  const [createStudent] = useMutation(CREATE_STUDENT, {
    onError: (error: ApolloError) => {
      logError(error.message);
    },
    onCompleted: (data) => {
      if (data.createStudent.student?.id) {
        logInfo(`Student created: ${data.createStudent.student.id}`);
      } else {
        logError("Unknown create student error");
      }
    },
  });

  // Internal tool, only available on localhost
  if (window.location.hostname !== "localhost") {
    return null;
  }

  const tryAddStudent = (studentData: Record<string, unknown>) => {
    const { firstName, surname, email, grade, schoolId } = studentData;

    if (
      firstName === undefined ||
      surname === undefined ||
      email === undefined ||
      grade === undefined ||
      schoolId === undefined
    ) {
      console.warn("Invalid student data", studentData);
      return;
    }

    const input = {
      firstName: String(firstName),
      surname: String(surname),
      email: String(email),
      grade: parseInt(String(grade)),
      schoolId: String(schoolId),
    };

    createStudent({
      variables: { input },
    });
  };

  const handleCsvImport = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results: ParseResult<Record<string, unknown>>) => {
        results.data.forEach((student) => {
          tryAddStudent(student);
        });
      },
    });
  };

  return (
    <Button component="label">
      <FormattedMessage id="studentsImportCsvButton" />
      <input hidden accept=".csv" type="file" onChange={handleCsvImport} />
    </Button>
  );
};

import { ApolloError, useMutation } from "@apollo/client";
import { Button, Stack } from "@mui/material";
import {
  CREATE_IMAGE_QUESTION,
  INIT_QUESTION_IMAGE_UPLOAD,
  UPDATE_IMAGE_QUESTION,
} from "../../graphql/Question";
import {
  CreateQuestionStatus,
  UpdateQuestionStatus,
} from "../../__generated__/graphql";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Page } from "../../components/Page/Page";
import { PageSection } from "../../components/PageSection/PageSection";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";
import { QuestionAnswer } from "../../mock/MockTypes";
import { QuestionEditorAnswers } from "../../components/QuestionEditorAnswers/QuestionEditorAnswers";
import { QuestionEditorMenu } from "../../components/QuestionEditorMenu/QuestionEditorMenu";
import { QuestionEditorProperties } from "../../components/QuestionEditorProperties/QuestionEditorProperties";
import { ROUTE } from "../../config/Routes";
import { logError } from "../../utils/Logger";
import styled from "@emotion/styled";
import { useAppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useSnackbarContext } from "../../context/SnackbarContext";

const ImagePreview = styled.img`
  max-height: 300px;
`;

export const QuestionImage = () => {
  const [grades, setGrades] = useState<number[]>([]);
  const [categoryId, setCategoryId] = useState("");
  const [difficultyId, setDifficultyId] = useState("");
  const [text, setText] = useState("");
  const [uploading, setUploading] = useState(false);
  const [answers, setAnswers] = useState<QuestionAnswer[]>([
    { text: "", correct: false },
  ]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const { showSnack } = useSnackbarContext();
  const { editQuestion } = useAppContext();
  const [initQuestionImageUpload] = useMutation(INIT_QUESTION_IMAGE_UPLOAD);
  const navigate = useNavigate();

  useEffect(() => {
    if (editQuestion) {
      const { grades, category, difficulty, text, answers } = editQuestion;
      setGrades(grades || []);
      setCategoryId(category?.id || "");
      setDifficultyId(difficulty?.id || "");
      setText(text || "");
      if (answers) {
        const parsedAnswers = answers.map((answer) => ({
          text: answer.text || "",
          correct: answer.correct || false,
        }));
        setAnswers(parsedAnswers);
      }
      if (
        editQuestion.__typename === "ImageEditorQuestion" &&
        editQuestion.image
      ) {
        setImage(editQuestion.image);
      }
    }
  }, [editQuestion]);

  const isFormValid = useMemo(() => {
    const answersAreValid =
      answers.filter((answer) => answer.text.length > 0).length > 1;
    const hasCorrectAnswer =
      answers.filter((answer) => answer.correct).length > 0;

    return (
      grades.length > 0 &&
      categoryId.length > 0 &&
      difficultyId.length > 0 &&
      text.length > 0 &&
      image &&
      image.length > 0 &&
      answersAreValid &&
      hasCorrectAnswer
    );
  }, [
    answers,
    grades,
    categoryId.length,
    difficultyId.length,
    text.length,
    image,
  ]);

  const imageUploadError = () => {
    showSnack(
      <FormattedMessage id="questionEditorImageUploadError" />,
      "error"
    );
    setUploading(false);
  };

  useEffect(() => {
    if (!selectedFile) {
      return;
    }
    (async () => {
      setUploading(true);
      try {
        const initUploadResponse = await initQuestionImageUpload();
        const uploadUrl =
          initUploadResponse.data?.initQuestionImageUpload.uploadUrl;

        if (!uploadUrl) {
          imageUploadError();
          logError("Image upload URL not found");
          return;
        }

        let formData = new FormData();
        formData.append("file", selectedFile);
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URI}${uploadUrl}`,
          {
            method: "POST",
            body: formData,
          }
        );
        const responseJson = await response.json();
        const imageUrl = responseJson?.url;
        if (imageUrl) {
          setImage(imageUrl);
          setUploading(false);
        } else {
          imageUploadError();
          logError("Image URL not returned");
        }
      } catch (error) {
        imageUploadError();
        logError("Image upload failed");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const mutationSuccess = () => {
    navigate(ROUTE.QUESTIONS.PATH);
  };

  const mutationError = (error: ApolloError) => {
    showSnack(<FormattedMessage id="unknownError" />, "error");
    logError(error.message);
  };

  const [createImageQuestion, createQueryStatus] = useMutation(
    CREATE_IMAGE_QUESTION,
    {
      onError: mutationError,
      onCompleted: (data) => {
        if (data.createImageQuestion?.status === CreateQuestionStatus.Success) {
          showSnack(
            <FormattedMessage id="questionEditorAddedMessage" />,
            "success"
          );
          mutationSuccess();
        } else {
          showSnack(<FormattedMessage id="unknownError" />, "error");
        }
      },
    }
  );

  const [updateImageQuestion, updateQueryStatus] = useMutation(
    UPDATE_IMAGE_QUESTION,
    {
      onError: mutationError,
      onCompleted: (data) => {
        if (data.updateImageQuestion?.status === UpdateQuestionStatus.Success) {
          showSnack(
            <FormattedMessage id="questionEditorUpdatedMessage" />,
            "success"
          );
          mutationSuccess();
        } else {
          showSnack(<FormattedMessage id="unknownError" />, "error");
        }
      },
    }
  );

  const handleSave = (draft: boolean) => {
    if (!image) {
      return;
    }
    const input = {
      draft,
      grades,
      difficulty: difficultyId,
      category: categoryId,
      text,
      image,
      answers: answers.map(({ text, correct }) => {
        return { text, correct };
      }),
    };
    if (editQuestion?.id) {
      updateImageQuestion({
        variables: {
          input: {
            id: editQuestion.id,
            ...input,
          },
        },
      });
    } else {
      createImageQuestion({
        variables: {
          input,
        },
      });
    }
  };

  return (
    <ProtectedRoute roles={ROUTE.QUESTION_IMAGE.ROLES}>
      <Page>
        <QuestionEditorProperties
          type="image"
          grades={grades}
          setGrades={setGrades}
          difficultyId={difficultyId}
          setDifficultyId={setDifficultyId}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
          text={text}
          setText={setText}
        />
        <PageSection title={<FormattedMessage id="questionEditorImageLabel" />}>
          <Stack alignItems="flex-start" spacing={2}>
            {image && <ImagePreview alt="Preview" src={image} />}
            <Button disabled={uploading} variant="contained" component="label">
              <FormattedMessage id="questionEditorImageUploadButton" />
              <input
                hidden
                accept="image/jpeg"
                type="file"
                onChange={onSelectFile}
              />
            </Button>
          </Stack>
        </PageSection>
        <QuestionEditorAnswers answers={answers} setAnswers={setAnswers} />
        <QuestionEditorMenu
          loading={createQueryStatus.loading || updateQueryStatus.loading}
          saveDisabled={!isFormValid}
          onSave={handleSave}
        />
      </Page>
    </ProtectedRoute>
  );
};

import {
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { GET_QUIZ_EVENT_AVAILABLE_COUNT } from "../../graphql/TestsStats";
import { PageSection } from "../../components/PageSection/PageSection";
import { TestsYearStatsNumber } from "./TestsYearStatsNumber";
import { logError } from "../../utils/Logger";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";

interface Props {
  grade: number;
}

export const TestsYearStats = ({ grade }: Props) => {
  const { loading, data, refetch } = useQuery(GET_QUIZ_EVENT_AVAILABLE_COUNT, {
    variables: {
      grade,
    },
    onError: (error) => {
      logError(error.message);
    },
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const availableQuizCount = data?.quizEventAvailableCount?.count;
  const details = (data?.quizEventAvailableCount?.details || []).flatMap((f) =>
    f ? [f] : []
  );

  return (
    <PageSection>
      <Stack spacing={1}>
        <Stack direction="row">
          <Typography variant="h6">{grade}</Typography>
          <Typography variant="h6">
            <FormattedMessage id="testsStatsGradeLabel" />
          </Typography>
        </Stack>
        {loading ? (
          <LinearProgress />
        ) : (
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>
                <FormattedMessage id="testsStatsCanGenerateTestsLabel" />:
              </Typography>
              <TestsYearStatsNumber value={availableQuizCount} />
            </Stack>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="testsStatsDifficultyLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="testsStatsUsedQuestionsLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="testsStatsRemainingQuestionsLabel" />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedMessage id="testsStatsCanGenerateTestsLabel" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.map((detail) => (
                    <TableRow key={detail.ID}>
                      <TableCell>{detail.name}</TableCell>
                      <TableCell>{detail.usedQuestions}</TableCell>
                      <TableCell>{detail.remainingQuestions}</TableCell>
                      <TableCell align="right">
                        <TestsYearStatsNumber
                          value={detail.possibleQuizCount}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Stack>
    </PageSection>
  );
};

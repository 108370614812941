import { Button, Stack } from "@mui/material";
import { AlertDialog } from "../AlertDialog/AlertDialog";
import { FormattedMessage } from "react-intl";
import { PageSection } from "../PageSection/PageSection";
import { ROUTE } from "../../config/Routes";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface Props {
  loading: boolean;
  saveDisabled: boolean;
  onSave: (draft: boolean) => void;
}

export const QuestionEditorMenu = ({
  loading,
  saveDisabled,
  onSave,
}: Props) => {
  const navigate = useNavigate();
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const handleCancel = () => {
    setShowCancelDialog(true);
  };

  const handleCancelDialogClose = () => {
    setShowCancelDialog(false);
  };

  const handleCancelDialogConfirm = () => {
    setShowCancelDialog(false);
    navigate(ROUTE.QUESTIONS.PATH);
  };

  const handleActiveSave = () => {
    onSave(false);
  };

  const handleDraftSave = () => {
    onSave(true);
  };

  return (
    <>
      <PageSection>
        <Stack direction="row" spacing={2}>
          <Button
            disabled={loading || saveDisabled}
            variant="contained"
            onClick={handleActiveSave}
          >
            {loading ? (
              <FormattedMessage id="savingMessage" />
            ) : (
              <FormattedMessage id="questionEditorSavePublichButton" />
            )}
          </Button>
          <Button
            disabled={loading || saveDisabled}
            variant="contained"
            color="info"
            onClick={handleDraftSave}
          >
            {loading ? (
              <FormattedMessage id="savingMessage" />
            ) : (
              <FormattedMessage id="questionEditorSaveDraftButton" />
            )}
          </Button>
          <Button disabled={loading} onClick={handleCancel}>
            <FormattedMessage id="cancelButton" />
          </Button>
        </Stack>
      </PageSection>
      <AlertDialog
        title={<FormattedMessage id="questionEditorCancelDialogTitle" />}
        description={
          <FormattedMessage id="questionEditorCancelDialogDescription" />
        }
        open={showCancelDialog}
        onClose={handleCancelDialogClose}
        onConfirm={handleCancelDialogConfirm}
      />
    </>
  );
};

import { AuthRole } from "../../config/Enums";
import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { GET_STUDENTS } from "../../graphql/Students";
import Papa from "papaparse";
import { useAppContext } from "../../context/AppContext";
import { useLazyQuery } from "@apollo/client";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useState } from "react";

export const StudentsCsvExportButton = () => {
  const { userRole } = useAppContext();
  const [exporting, setExporting] = useState(false);
  const { showSnack } = useSnackbarContext();

  const [getStudents] = useLazyQuery(GET_STUDENTS, {
    onCompleted: (data) => {
      const studentsData = (
        data.students?.edges?.map((edge) => edge?.node) || []
      ).flatMap((f) => (f ? [f] : []));

      const students = studentsData.map((student) => {
        const { surname, firstName, email, grade, school } = student;
        return {
          surname,
          firstName,
          email,
          grade,
          school: school?.name,
        };
      });
      var csv = Papa.unparse(students);

      // Download CSV file
      var hiddenElement = document.createElement("a");
      hiddenElement.href = window.URL.createObjectURL(new Blob([csv]));
      hiddenElement.target = "_blank";
      hiddenElement.download = "students.csv";
      hiddenElement.click();
      hiddenElement.remove();

      setExporting(false);
    },
    onError: (error) => {
      showSnack(error.message, "error");
      setExporting(false);
    },
  });

  const handleExport = () => {
    setExporting(true);
    getStudents({
      fetchPolicy: "network-only",
      variables: { first: 100000 },
    });
  };

  if (userRole !== AuthRole.ADMIN) {
    return null;
  }

  return (
    <Button disabled={exporting} onClick={handleExport}>
      <FormattedMessage id="studentsExportCsvButton" />
    </Button>
  );
};

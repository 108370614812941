import { ApolloError, useMutation } from "@apollo/client";
import { Avatar, IconButton, Stack } from "@mui/material";
import { AlertDialog } from "../AlertDialog/AlertDialog";
import ClearIcon from "@mui/icons-material/Clear";
import { FormattedMessage } from "react-intl";
import { Student } from "../../__generated__/graphql";
import { UPDATE_STUDENT } from "../../graphql/Students";
import { logError } from "../../utils/Logger";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useState } from "react";

interface Props {
  data: Student;
  onUpdate: () => void;
}

export const StudentAvatarManagement = ({ data, onUpdate }: Props) => {
  const [deleteAvatarId, setDeleteAvatarId] = useState<string>();
  const { id, avatar } = data;
  const { showSnack } = useSnackbarContext();

  const mutationSuccess = () => {
    onUpdate();
  };

  const mutationError = (error: ApolloError) => {
    showSnack(<FormattedMessage id="unknownError" />, "error");
    logError(error.message);
  };

  const [updateStudent, updateStatus] = useMutation(UPDATE_STUDENT, {
    onError: mutationError,
    onCompleted: (data) => {
      if (data.updateStudent.student?.id) {
        showSnack(
          <FormattedMessage id="studentsDeletedAvatarMessage" />,
          "success"
        );
        mutationSuccess();
      } else {
        showSnack(<FormattedMessage id="unknownError" />, "error");
      }
    },
  });

  const handleCloseDeleteAvatarDialog = () => {
    setDeleteAvatarId(undefined);
  };

  const handleConfirmDeleteAvatar = () => {
    if (deleteAvatarId) {
      updateStudent({
        variables: {
          input: {
            id: deleteAvatarId,
            avatar: null,
          },
        },
      });
    }
    setDeleteAvatarId(undefined);
  };

  if (!id || !avatar) {
    return null;
  }

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Avatar src={avatar} />
        <IconButton
          color="error"
          disabled={updateStatus.loading}
          onClick={() => {
            setDeleteAvatarId(id);
          }}
        >
          <ClearIcon />
        </IconButton>
      </Stack>
      <AlertDialog
        title={<FormattedMessage id="studentsDeleteAvatarDialogTitle" />}
        description={
          <FormattedMessage id="studentsDeleteAvatarDialogDescription" />
        }
        open={deleteAvatarId !== undefined}
        onClose={handleCloseDeleteAvatarDialog}
        onConfirm={handleConfirmDeleteAvatar}
      />
    </>
  );
};

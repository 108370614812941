import { gql } from "../__generated__/gql";

export const GET_QUESTION_DIFFUCULTY_LEVELS = gql(`
  query GetQuestionDifficultyLevels {
    questionDifficultyLevels {
      id
      name
      points
      countInQuiz
    }
  }
`);

export const CREATE_QUESTION_DIFFICULTY = gql(`
  mutation CreateQuestionDifficulty($input: CreateQuestionDifficultyInput!) {
    createQuestionDifficulty(input: $input) {
      difficulty {
        id
      }
    }
  }
`);

export const UPDATE_QUESTION_DIFFICULTY = gql(`
  mutation UpdateQuestionDifficulty($input: UpdateQuestionDifficultyInput!) {
    updateQuestionDifficulty(input: $input) {
      status
    }
  }
`);

export const DELETE_QUESTION_DIFFICULTY = gql(`
  mutation DeleteQuestionDifficulty($id: ID!) {
    deleteQuestionDifficulty(id: $id) {
      status
    }
  }
`);

/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  query GetDashboardData( \n    $startDate: Date,\n    $endDate: Date,\n    $studentsLimit: Int,\n  ) {\n    schoolsAdminLeaderboard(\n        startDate: $startDate,\n        endDate: $endDate,\n    ) {\n        school {\n            id\n            name\n        }\n        rank\n        studentsCount\n        activeStudentsCount\n        points\n        averageAccuracy\n    }\n    studentsAdminLeaderboard(\n        startDate: $startDate,\n        endDate: $endDate, \n        first: $studentsLimit,\n    ) {\n      edges {\n        node {\n          student {\n            id\n            firstName\n            surname\n            school {\n              id\n              name\n            }\n            grade\n          }\n          stats {\n            points\n            accuracy\n            schoolRank\n            globalRank\n          }\n        }\n      }\n    }\n  }\n": types.GetDashboardDataDocument,
    "\n  query GetQuestions( \n    $category: ID,\n    $difficulty: ID,\n    $grade: Int,\n    $state: EditorQuestionState\n    $search: String\n  ) {\n    editorQuestions(\n      category: $category,\n      difficulty: $difficulty,\n      grade: $grade,\n      state: $state,\n      search: $search,\n    ) {\n      __typename\n      id\n      state\n      text\n      grades\n      difficulty {\n        id\n        name\n      }\n      category {\n        id\n        name\n      }\n      answers {\n        # TODO: Get ID once added to schema (CPD20-122)\n        text\n        correct\n      }\n      ... on ImageEditorQuestion {\n        image\n      }\n    }\n  }\n": types.GetQuestionsDocument,
    "\n  query QuestionsStats(\n    $category: ID,\n    $difficulty: ID,\n    $school: ID,\n    $grade: Int,\n    $search: String\n  ) {\n    questionsStats(\n      category: $category,\n      difficulty: $difficulty,\n      school: $school,\n      grade: $grade,\n      search: $search,\n    ) {\n      question {\n        id\n        text\n      }\n      maxPoints\n      maxPoints\n      averagePoints\n      readTimeLimit\n      timeLimit\n      averageTime\n      succesRate\n      totalAnswers\n      correctAnswers\n      wrongAnswers\n      maxPointsAnswers\n      timeouts\n    }\n  }\n": types.QuestionsStatsDocument,
    "\n  mutation CreateTextQuestion($input: CreateTextQuestionInput!) {\n    createTextQuestion(input: $input) {\n      status\n    }\n  }\n": types.CreateTextQuestionDocument,
    "\n  mutation CreateImageQuestion($input: CreateImageQuestionInput!) {\n    createImageQuestion(input: $input) {\n      status\n    }\n  }\n": types.CreateImageQuestionDocument,
    "\n  mutation UpdateTextQuestion($input: UpdateTextQuestionInput!) {\n    updateTextQuestion(input: $input) {\n      status\n    }\n  }\n": types.UpdateTextQuestionDocument,
    "\n  mutation UpdateImageQuestion($input: UpdateImageQuestionInput!) {\n    updateImageQuestion(input: $input) {\n      status\n    }\n  }\n": types.UpdateImageQuestionDocument,
    "\n  mutation DeleteQuestion($id: ID!) {\n    deleteQuestion(id: $id) {\n      status\n    }\n  }\n": types.DeleteQuestionDocument,
    "\n  mutation InitQuestionImageUpload {\n    initQuestionImageUpload {\n      uploadUrl\n    }\n  }\n": types.InitQuestionImageUploadDocument,
    "\n  query GetQuestionCategories {\n    questionCategories {\n      id\n      name\n    }\n  }\n": types.GetQuestionCategoriesDocument,
    "\n  mutation CreateQuestionCategory($input: CreateQuestionCategoryInput!) {\n    createQuestionCategory(input: $input) {\n      category {\n        id\n      }\n    }\n  }\n": types.CreateQuestionCategoryDocument,
    "\n  mutation UpdateQuestionCategory($input: UpdateQuestionCategoryInput!) {\n    updateQuestionCategory(input: $input) {\n      status\n    }\n  }\n": types.UpdateQuestionCategoryDocument,
    "\n  mutation DeleteQuestionCategory($id: ID!) {\n    deleteQuestionCategory(id: $id) {\n      status\n    }\n  }\n": types.DeleteQuestionCategoryDocument,
    "\n  query GetQuestionDifficultyLevels {\n    questionDifficultyLevels {\n      id\n      name\n      points\n      countInQuiz\n    }\n  }\n": types.GetQuestionDifficultyLevelsDocument,
    "\n  mutation CreateQuestionDifficulty($input: CreateQuestionDifficultyInput!) {\n    createQuestionDifficulty(input: $input) {\n      difficulty {\n        id\n      }\n    }\n  }\n": types.CreateQuestionDifficultyDocument,
    "\n  mutation UpdateQuestionDifficulty($input: UpdateQuestionDifficultyInput!) {\n    updateQuestionDifficulty(input: $input) {\n      status\n    }\n  }\n": types.UpdateQuestionDifficultyDocument,
    "\n  mutation DeleteQuestionDifficulty($id: ID!) {\n    deleteQuestionDifficulty(id: $id) {\n      status\n    }\n  }\n": types.DeleteQuestionDifficultyDocument,
    "\n  query GetSchools {\n    schools {\n      id\n      name\n    }\n  }\n": types.GetSchoolsDocument,
    "\n  mutation CreateSchool($input: CreateSchoolInput!) {\n    createSchool(input: $input) {\n      school {\n        id\n      }\n    }\n  }\n": types.CreateSchoolDocument,
    "\n  mutation UpdateSchool($input: UpdateSchoolInput!) {\n    updateSchool(input: $input) {\n      school {\n        id\n      }\n    }\n  }\n": types.UpdateSchoolDocument,
    "\n  mutation DeleteSchool($id: ID!) {\n    deleteSchool(id: $id) {\n      success\n    }\n  }\n": types.DeleteSchoolDocument,
    "\n  query GetSchoolsLeaderboard( \n    $startDate: Date,\n    $endDate: Date,\n  ) {\n    schoolsAdminLeaderboard(\n        startDate: $startDate,\n        endDate: $endDate,\n    ) {\n        school {\n            id\n            name\n        }\n        rank\n        studentsCount\n        activeStudentsCount\n        points\n        averagePoints\n        averageAccuracy\n    }\n  }\n": types.GetSchoolsLeaderboardDocument,
    "\n  query GetStudents( \n    $schoolId: ID,\n    $grade: Int,\n    $first: Int,\n    $after: Int\n  ) {\n    students(\n      schoolId: $schoolId,\n      grade: $grade,\n      first: $first,\n      after: $after\n    ) {\n      edges {\n        node {\n          id\n          firstName\n          surname\n          nickname\n          email\n          avatar\n          school {\n            id\n            name\n          }\n          grade\n          consents {\n            name\n            status\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.GetStudentsDocument,
    "\n  query GetStudentsLeaderboard( \n    $school: ID,\n    $grade: Int,\n    $startDate: Date,\n    $endDate: Date,\n    $first: Int,\n    $after: Int\n  ) {\n    studentsAdminLeaderboard(\n        school: $school,\n        grade: $grade,\n        startDate: $startDate,\n        endDate: $endDate,\n        first: $first,\n        after: $after\n    ) {\n      edges {\n        node {\n          student {\n            id\n            firstName\n            surname\n            nickname\n            email\n            avatar\n            school {\n              id\n              name\n            }\n            grade\n          }\n          stats {\n            points\n            maxPoints\n            completedQuizCount\n            totalQuizCount\n            accuracy\n            schoolRank\n            globalRank\n            totalStudents\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.GetStudentsLeaderboardDocument,
    "\n  mutation CreateStudent($input: CreateStudentInput!) {\n    createStudent(input: $input) {\n      student{\n        id\n      }\n    }\n  }\n": types.CreateStudentDocument,
    "\n  mutation UpdateStudent($input: UpdateStudentInput!) {\n    updateStudent(input: $input) {\n      student {\n        id\n      }\n    }\n  }\n": types.UpdateStudentDocument,
    "\n  mutation DeleteStudent($id: ID!) {\n    deleteStudent(id: $id) {\n      success\n    }\n  }\n": types.DeleteStudentDocument,
    "\n  query GetTeachers(\n    $first: Int,\n    $after: Int,\n    $schoolId: ID\n  ) {\n    teachers (\n      first: $first,\n      after: $after,\n      schoolId: $schoolId\n    ) {\n      edges {\n        node {\n          id\n          firstName\n          surname\n          email\n          school {\n            id\n            name\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.GetTeachersDocument,
    "\n  mutation CreateTeacher($input: CreateTeacherInput!) {\n    createTeacher(input: $input) {\n      teacher {\n        id\n      }\n    }\n  }\n": types.CreateTeacherDocument,
    "\n  mutation UpdateTeacher($input: UpdateTeacherInput!) {\n    updateTeacher(input: $input) {\n      teacher {\n        id\n      }\n    }\n  }\n": types.UpdateTeacherDocument,
    "\n  mutation DeleteTeacher($id: ID!) {\n    deleteTeacher(id: $id) {\n      success\n    }\n  }\n": types.DeleteTeacherDocument,
    "\n    query QuizEventCount {\n        quizEventCount\n    }\n": types.QuizEventCountDocument,
    "\n    query QuizEventAvailableCount(\n        $grade: Int\n    ) {\n        quizEventAvailableCount(\n            grade: $grade\n        ) {\n            count\n            details {\n                ID\n                name\n                usedQuestions\n                remainingQuestions\n                possibleQuizCount\n            }\n        }\n    }\n": types.QuizEventAvailableCountDocument,
    "\n    query GetUser {\n        me {\n            id\n            email\n            ... on Teacher {\n                school {\n                    id\n                    name\n                }\n            }\n        }\n    }\n": types.GetUserDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
**/
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDashboardData( \n    $startDate: Date,\n    $endDate: Date,\n    $studentsLimit: Int,\n  ) {\n    schoolsAdminLeaderboard(\n        startDate: $startDate,\n        endDate: $endDate,\n    ) {\n        school {\n            id\n            name\n        }\n        rank\n        studentsCount\n        activeStudentsCount\n        points\n        averageAccuracy\n    }\n    studentsAdminLeaderboard(\n        startDate: $startDate,\n        endDate: $endDate, \n        first: $studentsLimit,\n    ) {\n      edges {\n        node {\n          student {\n            id\n            firstName\n            surname\n            school {\n              id\n              name\n            }\n            grade\n          }\n          stats {\n            points\n            accuracy\n            schoolRank\n            globalRank\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetDashboardData( \n    $startDate: Date,\n    $endDate: Date,\n    $studentsLimit: Int,\n  ) {\n    schoolsAdminLeaderboard(\n        startDate: $startDate,\n        endDate: $endDate,\n    ) {\n        school {\n            id\n            name\n        }\n        rank\n        studentsCount\n        activeStudentsCount\n        points\n        averageAccuracy\n    }\n    studentsAdminLeaderboard(\n        startDate: $startDate,\n        endDate: $endDate, \n        first: $studentsLimit,\n    ) {\n      edges {\n        node {\n          student {\n            id\n            firstName\n            surname\n            school {\n              id\n              name\n            }\n            grade\n          }\n          stats {\n            points\n            accuracy\n            schoolRank\n            globalRank\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetQuestions( \n    $category: ID,\n    $difficulty: ID,\n    $grade: Int,\n    $state: EditorQuestionState\n    $search: String\n  ) {\n    editorQuestions(\n      category: $category,\n      difficulty: $difficulty,\n      grade: $grade,\n      state: $state,\n      search: $search,\n    ) {\n      __typename\n      id\n      state\n      text\n      grades\n      difficulty {\n        id\n        name\n      }\n      category {\n        id\n        name\n      }\n      answers {\n        # TODO: Get ID once added to schema (CPD20-122)\n        text\n        correct\n      }\n      ... on ImageEditorQuestion {\n        image\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetQuestions( \n    $category: ID,\n    $difficulty: ID,\n    $grade: Int,\n    $state: EditorQuestionState\n    $search: String\n  ) {\n    editorQuestions(\n      category: $category,\n      difficulty: $difficulty,\n      grade: $grade,\n      state: $state,\n      search: $search,\n    ) {\n      __typename\n      id\n      state\n      text\n      grades\n      difficulty {\n        id\n        name\n      }\n      category {\n        id\n        name\n      }\n      answers {\n        # TODO: Get ID once added to schema (CPD20-122)\n        text\n        correct\n      }\n      ... on ImageEditorQuestion {\n        image\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query QuestionsStats(\n    $category: ID,\n    $difficulty: ID,\n    $school: ID,\n    $grade: Int,\n    $search: String\n  ) {\n    questionsStats(\n      category: $category,\n      difficulty: $difficulty,\n      school: $school,\n      grade: $grade,\n      search: $search,\n    ) {\n      question {\n        id\n        text\n      }\n      maxPoints\n      maxPoints\n      averagePoints\n      readTimeLimit\n      timeLimit\n      averageTime\n      succesRate\n      totalAnswers\n      correctAnswers\n      wrongAnswers\n      maxPointsAnswers\n      timeouts\n    }\n  }\n"): (typeof documents)["\n  query QuestionsStats(\n    $category: ID,\n    $difficulty: ID,\n    $school: ID,\n    $grade: Int,\n    $search: String\n  ) {\n    questionsStats(\n      category: $category,\n      difficulty: $difficulty,\n      school: $school,\n      grade: $grade,\n      search: $search,\n    ) {\n      question {\n        id\n        text\n      }\n      maxPoints\n      maxPoints\n      averagePoints\n      readTimeLimit\n      timeLimit\n      averageTime\n      succesRate\n      totalAnswers\n      correctAnswers\n      wrongAnswers\n      maxPointsAnswers\n      timeouts\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateTextQuestion($input: CreateTextQuestionInput!) {\n    createTextQuestion(input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTextQuestion($input: CreateTextQuestionInput!) {\n    createTextQuestion(input: $input) {\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateImageQuestion($input: CreateImageQuestionInput!) {\n    createImageQuestion(input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation CreateImageQuestion($input: CreateImageQuestionInput!) {\n    createImageQuestion(input: $input) {\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateTextQuestion($input: UpdateTextQuestionInput!) {\n    updateTextQuestion(input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTextQuestion($input: UpdateTextQuestionInput!) {\n    updateTextQuestion(input: $input) {\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateImageQuestion($input: UpdateImageQuestionInput!) {\n    updateImageQuestion(input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateImageQuestion($input: UpdateImageQuestionInput!) {\n    updateImageQuestion(input: $input) {\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteQuestion($id: ID!) {\n    deleteQuestion(id: $id) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteQuestion($id: ID!) {\n    deleteQuestion(id: $id) {\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation InitQuestionImageUpload {\n    initQuestionImageUpload {\n      uploadUrl\n    }\n  }\n"): (typeof documents)["\n  mutation InitQuestionImageUpload {\n    initQuestionImageUpload {\n      uploadUrl\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetQuestionCategories {\n    questionCategories {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetQuestionCategories {\n    questionCategories {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateQuestionCategory($input: CreateQuestionCategoryInput!) {\n    createQuestionCategory(input: $input) {\n      category {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateQuestionCategory($input: CreateQuestionCategoryInput!) {\n    createQuestionCategory(input: $input) {\n      category {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateQuestionCategory($input: UpdateQuestionCategoryInput!) {\n    updateQuestionCategory(input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateQuestionCategory($input: UpdateQuestionCategoryInput!) {\n    updateQuestionCategory(input: $input) {\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteQuestionCategory($id: ID!) {\n    deleteQuestionCategory(id: $id) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteQuestionCategory($id: ID!) {\n    deleteQuestionCategory(id: $id) {\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetQuestionDifficultyLevels {\n    questionDifficultyLevels {\n      id\n      name\n      points\n      countInQuiz\n    }\n  }\n"): (typeof documents)["\n  query GetQuestionDifficultyLevels {\n    questionDifficultyLevels {\n      id\n      name\n      points\n      countInQuiz\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateQuestionDifficulty($input: CreateQuestionDifficultyInput!) {\n    createQuestionDifficulty(input: $input) {\n      difficulty {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateQuestionDifficulty($input: CreateQuestionDifficultyInput!) {\n    createQuestionDifficulty(input: $input) {\n      difficulty {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateQuestionDifficulty($input: UpdateQuestionDifficultyInput!) {\n    updateQuestionDifficulty(input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateQuestionDifficulty($input: UpdateQuestionDifficultyInput!) {\n    updateQuestionDifficulty(input: $input) {\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteQuestionDifficulty($id: ID!) {\n    deleteQuestionDifficulty(id: $id) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteQuestionDifficulty($id: ID!) {\n    deleteQuestionDifficulty(id: $id) {\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSchools {\n    schools {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetSchools {\n    schools {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateSchool($input: CreateSchoolInput!) {\n    createSchool(input: $input) {\n      school {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSchool($input: CreateSchoolInput!) {\n    createSchool(input: $input) {\n      school {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateSchool($input: UpdateSchoolInput!) {\n    updateSchool(input: $input) {\n      school {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSchool($input: UpdateSchoolInput!) {\n    updateSchool(input: $input) {\n      school {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteSchool($id: ID!) {\n    deleteSchool(id: $id) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSchool($id: ID!) {\n    deleteSchool(id: $id) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSchoolsLeaderboard( \n    $startDate: Date,\n    $endDate: Date,\n  ) {\n    schoolsAdminLeaderboard(\n        startDate: $startDate,\n        endDate: $endDate,\n    ) {\n        school {\n            id\n            name\n        }\n        rank\n        studentsCount\n        activeStudentsCount\n        points\n        averagePoints\n        averageAccuracy\n    }\n  }\n"): (typeof documents)["\n  query GetSchoolsLeaderboard( \n    $startDate: Date,\n    $endDate: Date,\n  ) {\n    schoolsAdminLeaderboard(\n        startDate: $startDate,\n        endDate: $endDate,\n    ) {\n        school {\n            id\n            name\n        }\n        rank\n        studentsCount\n        activeStudentsCount\n        points\n        averagePoints\n        averageAccuracy\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetStudents( \n    $schoolId: ID,\n    $grade: Int,\n    $first: Int,\n    $after: Int\n  ) {\n    students(\n      schoolId: $schoolId,\n      grade: $grade,\n      first: $first,\n      after: $after\n    ) {\n      edges {\n        node {\n          id\n          firstName\n          surname\n          nickname\n          email\n          avatar\n          school {\n            id\n            name\n          }\n          grade\n          consents {\n            name\n            status\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetStudents( \n    $schoolId: ID,\n    $grade: Int,\n    $first: Int,\n    $after: Int\n  ) {\n    students(\n      schoolId: $schoolId,\n      grade: $grade,\n      first: $first,\n      after: $after\n    ) {\n      edges {\n        node {\n          id\n          firstName\n          surname\n          nickname\n          email\n          avatar\n          school {\n            id\n            name\n          }\n          grade\n          consents {\n            name\n            status\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetStudentsLeaderboard( \n    $school: ID,\n    $grade: Int,\n    $startDate: Date,\n    $endDate: Date,\n    $first: Int,\n    $after: Int\n  ) {\n    studentsAdminLeaderboard(\n        school: $school,\n        grade: $grade,\n        startDate: $startDate,\n        endDate: $endDate,\n        first: $first,\n        after: $after\n    ) {\n      edges {\n        node {\n          student {\n            id\n            firstName\n            surname\n            nickname\n            email\n            avatar\n            school {\n              id\n              name\n            }\n            grade\n          }\n          stats {\n            points\n            maxPoints\n            completedQuizCount\n            totalQuizCount\n            accuracy\n            schoolRank\n            globalRank\n            totalStudents\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetStudentsLeaderboard( \n    $school: ID,\n    $grade: Int,\n    $startDate: Date,\n    $endDate: Date,\n    $first: Int,\n    $after: Int\n  ) {\n    studentsAdminLeaderboard(\n        school: $school,\n        grade: $grade,\n        startDate: $startDate,\n        endDate: $endDate,\n        first: $first,\n        after: $after\n    ) {\n      edges {\n        node {\n          student {\n            id\n            firstName\n            surname\n            nickname\n            email\n            avatar\n            school {\n              id\n              name\n            }\n            grade\n          }\n          stats {\n            points\n            maxPoints\n            completedQuizCount\n            totalQuizCount\n            accuracy\n            schoolRank\n            globalRank\n            totalStudents\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateStudent($input: CreateStudentInput!) {\n    createStudent(input: $input) {\n      student{\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateStudent($input: CreateStudentInput!) {\n    createStudent(input: $input) {\n      student{\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateStudent($input: UpdateStudentInput!) {\n    updateStudent(input: $input) {\n      student {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateStudent($input: UpdateStudentInput!) {\n    updateStudent(input: $input) {\n      student {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteStudent($id: ID!) {\n    deleteStudent(id: $id) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteStudent($id: ID!) {\n    deleteStudent(id: $id) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTeachers(\n    $first: Int,\n    $after: Int,\n    $schoolId: ID\n  ) {\n    teachers (\n      first: $first,\n      after: $after,\n      schoolId: $schoolId\n    ) {\n      edges {\n        node {\n          id\n          firstName\n          surname\n          email\n          school {\n            id\n            name\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTeachers(\n    $first: Int,\n    $after: Int,\n    $schoolId: ID\n  ) {\n    teachers (\n      first: $first,\n      after: $after,\n      schoolId: $schoolId\n    ) {\n      edges {\n        node {\n          id\n          firstName\n          surname\n          email\n          school {\n            id\n            name\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateTeacher($input: CreateTeacherInput!) {\n    createTeacher(input: $input) {\n      teacher {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTeacher($input: CreateTeacherInput!) {\n    createTeacher(input: $input) {\n      teacher {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateTeacher($input: UpdateTeacherInput!) {\n    updateTeacher(input: $input) {\n      teacher {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTeacher($input: UpdateTeacherInput!) {\n    updateTeacher(input: $input) {\n      teacher {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteTeacher($id: ID!) {\n    deleteTeacher(id: $id) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteTeacher($id: ID!) {\n    deleteTeacher(id: $id) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query QuizEventCount {\n        quizEventCount\n    }\n"): (typeof documents)["\n    query QuizEventCount {\n        quizEventCount\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query QuizEventAvailableCount(\n        $grade: Int\n    ) {\n        quizEventAvailableCount(\n            grade: $grade\n        ) {\n            count\n            details {\n                ID\n                name\n                usedQuestions\n                remainingQuestions\n                possibleQuizCount\n            }\n        }\n    }\n"): (typeof documents)["\n    query QuizEventAvailableCount(\n        $grade: Int\n    ) {\n        quizEventAvailableCount(\n            grade: $grade\n        ) {\n            count\n            details {\n                ID\n                name\n                usedQuestions\n                remainingQuestions\n                possibleQuizCount\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetUser {\n        me {\n            id\n            email\n            ... on Teacher {\n                school {\n                    id\n                    name\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetUser {\n        me {\n            id\n            email\n            ... on Teacher {\n                school {\n                    id\n                    name\n                }\n            }\n        }\n    }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;
import { LinearProgress, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { GET_QUIZ_EVENT_COUNT } from "../../graphql/TestsStats";
import { Page } from "../../components/Page/Page";
import { PageSection } from "../../components/PageSection/PageSection";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";
import { QUESTION_GRADE_OPTIONS } from "../../config/Questions";
import { ROUTE } from "../../config/Routes";
import { TestsYearStats } from "../../components/TestsYearStats/TestsYearStats";
import { logError } from "../../utils/Logger";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useSnackbarContext } from "../../context/SnackbarContext";

export const TestsStats = () => {
  const { showSnack } = useSnackbarContext();

  const quizEventCountQuery = useQuery(GET_QUIZ_EVENT_COUNT, {
    onError: (error) => {
      showSnack(<FormattedMessage id="unknownError" />, "error");
      logError(error.message);
    },
  });

  useEffect(() => {
    quizEventCountQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProtectedRoute roles={ROUTE.TESTS_STATS.ROLES}>
      <Page>
        <PageSection
          title={<FormattedMessage id="testsStatsGeneratedTestsLabel" />}
        >
          {quizEventCountQuery.loading && <LinearProgress />}
          <Typography variant="h4">
            {quizEventCountQuery.data?.quizEventCount}
          </Typography>
        </PageSection>
        {QUESTION_GRADE_OPTIONS.map((grade) => (
          <TestsYearStats key={grade} grade={grade} />
        ))}
      </Page>
    </ProtectedRoute>
  );
};

import { Navigate, useLocation } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import { DesignTokens } from "../../style/DesignTokens";
import { FormattedMessage } from "react-intl";
import { InteractionStatus } from "@azure/msal-browser";
import { ROUTE } from "../../config/Routes";
import { logError } from "../../utils/Logger";
import { loginRequest } from "../../config/AuthConfig";
import styled from "@emotion/styled";
import { useSnackbarContext } from "../../context/SnackbarContext";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginButton = styled(Button)`
  min-width: 120px;
  margin-top: ${DesignTokens.spacing.space32};
`;

export const Login = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const { showSnack } = useSnackbarContext();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      logError(e);
      showSnack(<FormattedMessage id="loginFailedMessage" />, "error");
    });
  };

  if (isAuthenticated) {
    const origin = location.state?.from?.pathname || ROUTE.DASHBOARD.PATH;
    return <Navigate to={origin} replace />;
  }

  if (inProgress !== InteractionStatus.None) {
    return (
      <Wrapper>
        <FormattedMessage id="loadingMessage" />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Form>
        <img src="./assets/img/logo.svg" alt="Logo" />
        <LoginButton variant="contained" onClick={handleLogin}>
          <FormattedMessage id="loginButton" />
        </LoginButton>
      </Form>
    </Wrapper>
  );
};

import { gql } from "../__generated__/gql";

export const GET_SCHOOLS = gql(`
  query GetSchools {
    schools {
      id
      name
    }
  }
`);

export const CREATE_SCHOOL = gql(`
  mutation CreateSchool($input: CreateSchoolInput!) {
    createSchool(input: $input) {
      school {
        id
      }
    }
  }
`);

export const UPDATE_SCHOOL = gql(`
  mutation UpdateSchool($input: UpdateSchoolInput!) {
    updateSchool(input: $input) {
      school {
        id
      }
    }
  }
`);

export const DELETE_SCHOOL = gql(`
  mutation DeleteSchool($id: ID!) {
    deleteSchool(id: $id) {
      success
    }
  }
`);

export const GET_SCHOOLS_LEADERBOARD = gql(`
  query GetSchoolsLeaderboard( 
    $startDate: Date,
    $endDate: Date,
  ) {
    schoolsAdminLeaderboard(
        startDate: $startDate,
        endDate: $endDate,
    ) {
        school {
            id
            name
        }
        rank
        studentsCount
        activeStudentsCount
        points
        averagePoints
        averageAccuracy
    }
  }
`);

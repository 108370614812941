export const DesignTokens = {
  colors: {
    primary: "#F24F00",
    grey700: "#12121D",
    green: "#00ff6a",
    orange: "#ffae00",
    red: "#ff0000",
  },
  spacing: {
    space8: "8px",
    space16: "16px",
    space32: "32px",
  },
};

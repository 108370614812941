import {
  Button,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { AccuracyValue } from "../../components/AccuracyValue/AccuracyValue";
import { GET_DASHBOARD_DATA } from "../../graphql/Dashboard";
import { Page } from "../../components/Page/Page";
import { PageSection } from "../../components/PageSection/PageSection";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";
import { ROUTE } from "../../config/Routes";
import { concatUserName } from "../../utils/UserUtils";
import { getIsoDate } from "../../utils/TimeUtils";
import { subYears } from "date-fns";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

export const Dashboard = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dashboardQuery = useQuery(GET_DASHBOARD_DATA, {
    variables: {
      studentsLimit: 3,
      startDate: getIsoDate(subYears(new Date(), 1)),
      endDate: getIsoDate(new Date()),
    },
  });

  useEffect(() => {
    dashboardQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schoolsLeaderboard = dashboardQuery.data?.schoolsAdminLeaderboard || [];
  const studentsLeaderboard = (
    dashboardQuery.data?.studentsAdminLeaderboard?.edges?.map(
      (edge) => edge?.node
    ) || []
  ).flatMap((f) => (f ? [f] : []));

  return (
    <ProtectedRoute roles={ROUTE.DASHBOARD.ROLES}>
      <Page>
        <PageSection
          title={intl.formatMessage({ id: "dashboardSchoolsTitle" })}
        >
          {dashboardQuery.loading && <LinearProgress />}
          <Stack alignItems="flex-start" spacing={2}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="rankLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="nameLabel" />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedMessage id="pointsLabel" />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedMessage id="averageAccuracyLabel" />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedMessage id="studentsLabel" />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedMessage id="activeStudentsLabel" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schoolsLeaderboard.map((school) => {
                    return (
                      <TableRow key={school.school?.id}>
                        <TableCell>{school.rank}</TableCell>
                        <TableCell>{school.school?.name}</TableCell>
                        <TableCell align="right">{school.points}</TableCell>
                        <TableCell align="right">
                          {school.averageAccuracy && (
                            <AccuracyValue value={school.averageAccuracy} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {school.studentsCount}
                        </TableCell>
                        <TableCell align="right">
                          {school.activeStudentsCount}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              onClick={() => {
                navigate(ROUTE.SCHOOLS_STATS.PATH);
              }}
            >
              <FormattedMessage id="dashboardMoreSchoolStats" />
            </Button>
          </Stack>
        </PageSection>
        <PageSection
          title={intl.formatMessage({ id: "dashboardStudentsTitle" })}
        >
          {dashboardQuery.loading && <LinearProgress />}
          <Stack alignItems="flex-start" spacing={2}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="globalRankLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="schoolRankLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="pointsLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="nameLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="schoolLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="gradeLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="accuracyLabel" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentsLeaderboard.map((student) => {
                    return (
                      <TableRow key={student.student?.id}>
                        <TableCell>{student.stats?.globalRank}</TableCell>
                        <TableCell>{student.stats?.schoolRank}</TableCell>
                        <TableCell>{student.stats?.points}</TableCell>
                        <TableCell>
                          {student.student
                            ? concatUserName(student.student)
                            : ""}
                        </TableCell>
                        <TableCell>{student.student?.school?.name}</TableCell>
                        <TableCell>{student.student?.grade}</TableCell>
                        <TableCell>
                          {student.stats?.accuracy && (
                            <AccuracyValue value={student.stats.accuracy} />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              onClick={() => {
                navigate(ROUTE.STUDENTS_STATS.PATH);
              }}
            >
              <FormattedMessage id="dashboardMoreStudentStats" />
            </Button>
          </Stack>
        </PageSection>
      </Page>
    </ProtectedRoute>
  );
};

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { GET_SCHOOLS } from "../../graphql/Schools";
import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";

const Wrapper = styled(FormControl)`
  min-width: 150px;
`;

interface Props {
  schoolId: string;
  setSchoolId: (schoolId: string) => void;
  size?: "small" | "medium" | undefined;
  showAllOption?: boolean;
}

export const SchoolSelect = ({
  schoolId,
  setSchoolId,
  size = "small",
  showAllOption = true,
}: Props) => {
  const schoolsQuery = useQuery(GET_SCHOOLS);
  const schools = (schoolsQuery.data?.schools || []).flatMap((f) =>
    f ? [f] : []
  );

  const handleSchoolChange = (event: SelectChangeEvent<string>) => {
    setSchoolId(event.target.value);
  };

  return (
    <Wrapper size={size}>
      <InputLabel>
        <FormattedMessage id="schoolSelect" />
      </InputLabel>
      <Select
        disabled={schoolsQuery.loading}
        value={schoolId}
        label={<FormattedMessage id="schoolSelect" />}
        onChange={handleSchoolChange}
      >
        {showAllOption && (
          <MenuItem value="">
            <FormattedMessage id="selectAllOption" />
          </MenuItem>
        )}
        {schools.map((school) => {
          const { id, name } = school;
          return id && name ? (
            <MenuItem key={name} value={id}>
              {name}
            </MenuItem>
          ) : null;
        })}
      </Select>
    </Wrapper>
  );
};

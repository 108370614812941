import { ApolloError, useMutation } from "@apollo/client";
import {
  CREATE_TEXT_QUESTION,
  UPDATE_TEXT_QUESTION,
} from "../../graphql/Question";
import {
  CreateQuestionStatus,
  UpdateQuestionStatus,
} from "../../__generated__/graphql";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Page } from "../../components/Page/Page";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";
import { QuestionAnswer } from "../../mock/MockTypes";
import { QuestionEditorAnswers } from "../../components/QuestionEditorAnswers/QuestionEditorAnswers";
import { QuestionEditorMenu } from "../../components/QuestionEditorMenu/QuestionEditorMenu";
import { QuestionEditorProperties } from "../../components/QuestionEditorProperties/QuestionEditorProperties";
import { ROUTE } from "../../config/Routes";
import { logError } from "../../utils/Logger";
import { useAppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useSnackbarContext } from "../../context/SnackbarContext";

export const QuestionText = () => {
  const [grades, setGrades] = useState<number[]>([]);
  const [categoryId, setCategoryId] = useState("");
  const [difficultyId, setDifficultyId] = useState("");
  const [text, setText] = useState("");
  const [answers, setAnswers] = useState<QuestionAnswer[]>([
    { text: "", correct: false },
  ]);
  const { showSnack } = useSnackbarContext();
  const { editQuestion } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (editQuestion) {
      const { grades, category, difficulty, text, answers } = editQuestion;
      setGrades(grades || []);
      setCategoryId(category?.id || "");
      setDifficultyId(difficulty?.id || "");
      setText(text || "");
      if (answers) {
        const parsedAnswers = answers.map((answer) => ({
          text: answer.text || "",
          correct: answer.correct || false,
        }));
        setAnswers(parsedAnswers);
      }
    }
  }, [editQuestion]);

  const isFormValid = useMemo(() => {
    const answersAreValid =
      answers.filter((answer) => answer.text.length > 0).length > 1;
    const hasCorrectAnswer =
      answers.filter((answer) => answer.correct).length > 0;

    return (
      grades.length > 0 &&
      categoryId.length > 0 &&
      difficultyId.length > 0 &&
      text.length > 0 &&
      answersAreValid &&
      hasCorrectAnswer
    );
  }, [answers, grades, categoryId.length, difficultyId.length, text.length]);

  const mutationSuccess = () => {
    navigate(ROUTE.QUESTIONS.PATH);
  };

  const mutationError = (error: ApolloError) => {
    showSnack(<FormattedMessage id="unknownError" />, "error");
    logError(error.message);
  };

  const [createTextQuestion, createQueryStatus] = useMutation(
    CREATE_TEXT_QUESTION,
    {
      onError: mutationError,
      onCompleted: (data) => {
        if (data.createTextQuestion?.status === CreateQuestionStatus.Success) {
          showSnack(
            <FormattedMessage id="questionEditorAddedMessage" />,
            "success"
          );
          mutationSuccess();
        } else {
          showSnack(<FormattedMessage id="unknownError" />, "error");
        }
      },
    }
  );

  const [updateTextQuestion, updateQueryStatus] = useMutation(
    UPDATE_TEXT_QUESTION,
    {
      onError: mutationError,
      onCompleted: (data) => {
        if (data.updateTextQuestion?.status === UpdateQuestionStatus.Success) {
          showSnack(
            <FormattedMessage id="questionEditorUpdatedMessage" />,
            "success"
          );
          mutationSuccess();
        } else {
          showSnack(<FormattedMessage id="unknownError" />, "error");
        }
      },
    }
  );

  const handleSave = (draft: boolean) => {
    const input = {
      draft,
      grades,
      difficulty: difficultyId,
      category: categoryId,
      text,
      answers: answers.map(({ text, correct }) => {
        return { text, correct };
      }),
    };
    if (editQuestion?.id) {
      updateTextQuestion({
        variables: {
          input: {
            id: editQuestion.id,
            ...input,
          },
        },
      });
    } else {
      createTextQuestion({
        variables: {
          input,
        },
      });
    }
  };

  return (
    <ProtectedRoute roles={ROUTE.QUESTION_TEXT.ROLES}>
      <Page>
        <QuestionEditorProperties
          type="text"
          grades={grades}
          setGrades={setGrades}
          difficultyId={difficultyId}
          setDifficultyId={setDifficultyId}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
          text={text}
          setText={setText}
        />
        <QuestionEditorAnswers answers={answers} setAnswers={setAnswers} />
        <QuestionEditorMenu
          loading={createQueryStatus.loading || updateQueryStatus.loading}
          saveDisabled={!isFormValid}
          onSave={handleSave}
        />
      </Page>
    </ProtectedRoute>
  );
};

import {
  Button,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DELETE_STUDENT, GET_STUDENTS } from "../../graphql/Students";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { AlertDialog } from "../../components/AlertDialog/AlertDialog";
import { AuthRole } from "../../config/Enums";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { FormattedMessage } from "react-intl";
import { GradeSelect } from "../../components/GradeSelect/GradeSelect";
import { Page } from "../../components/Page/Page";
import { PageSection } from "../../components/PageSection/PageSection";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";
import { ROUTE } from "../../config/Routes";
import { SchoolSelect } from "../../components/SchoolSelect/SchoolSelect";
import { Student } from "../../__generated__/graphql";
import { StudentAvatarManagement } from "../../components/StudentAvatarManagement/StudentAvatarManagement";
import { StudentConsents } from "../../components/StudentConsents/StudentConsents";
import { StudentDialog } from "../../components/StudentDialog/StudentDialog";
import { StudentNicknameManagement } from "../../components/StudentNicknameManagement/StudentNicknameManagement";
import { StudentsCsvExportButton } from "../../components/StudentsCsvExportButton/StudentsCsvExportButton";
import { StudentsCsvImportButton } from "../../components/StudentsCsvImportButton/StudentsCsvImportButton";
import { concatUserName } from "../../utils/UserUtils";
import { logError } from "../../utils/Logger";
import { useAppContext } from "../../context/AppContext";
import { useSnackbarContext } from "../../context/SnackbarContext";

const PAGE_SIZE = 30;

export const Students = () => {
  const { userRole } = useAppContext();
  const teacherMode = userRole === AuthRole.TEACHER;

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [grade, setGrade] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [selectedStudent, setSelectedStudent] = useState<Student | undefined>(
    undefined
  );
  const [deleteId, setDeleteId] = useState<string>();
  const { showSnack } = useSnackbarContext();

  const studentsQuery = useQuery(GET_STUDENTS, {
    variables: { first: PAGE_SIZE },
    onError(error) {
      showSnack(error.message, "error");
      logError(error.message);
    },
  });
  const studentsLeaderboardData = (
    studentsQuery.data?.students?.edges?.map((edge) => edge?.node) || []
  ).flatMap((f) => (f ? [f] : []));
  const pageInfo = studentsQuery.data?.students?.pageInfo;

  useEffect(() => {
    studentsQuery.refetch({
      schoolId: schoolId.length > 0 ? schoolId : undefined,
      grade: grade.length > 0 ? parseInt(grade) : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId, grade]);

  const loadMore = () => {
    studentsQuery.fetchMore({
      variables: { after: pageInfo?.endCursor },
    });
  };

  const [deleteStudent, deleteStatus] = useMutation(DELETE_STUDENT, {
    onError: (error) => {
      showSnack(<FormattedMessage id="unknownError" />, "error");
      logError(error.message);
    },
    onCompleted: (data) => {
      if (data.deleteStudent.success) {
        showSnack(<FormattedMessage id="studentsDeletedMessage" />, "success");
        studentsQuery.refetch();
      } else {
        showSnack(<FormattedMessage id="unknownError" />, "error");
      }
    },
  });

  const handleAdd = () => {
    setSelectedStudent(undefined);
    setShowEditDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(undefined);
  };

  const handleConfirmDelete = () => {
    if (deleteId) {
      deleteStudent({ variables: { id: deleteId } });
    }
    setDeleteId(undefined);
  };

  const handleCloseEditDialog = () => {
    setShowEditDialog(false);
  };

  const handleFilterReset = () => {
    setSchoolId("");
    setGrade("");
  };

  return (
    <>
      <ProtectedRoute roles={ROUTE.STUDENTS.ROLES}>
        <Page>
          <PageSection>
            <Stack direction="row" spacing={1}>
              <Button onClick={handleAdd} variant="contained">
                <FormattedMessage id="studentsAddButton" />
              </Button>
              <StudentsCsvImportButton />
              <StudentsCsvExportButton />
            </Stack>
          </PageSection>
          <PageSection title={<FormattedMessage id="filterLabel" />}>
            <Stack direction="row" spacing={1}>
              {!teacherMode && (
                <SchoolSelect schoolId={schoolId} setSchoolId={setSchoolId} />
              )}
              <GradeSelect grade={grade} setGrade={setGrade} />
              <Button onClick={handleFilterReset}>
                <FormattedMessage id="resetFiltersButton" />
              </Button>
            </Stack>
          </PageSection>
          <PageSection>
            {(studentsQuery.loading || deleteStatus.loading) && (
              <LinearProgress />
            )}
            <Stack spacing={2}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="nameLabel" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="emailLabel" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="consentsLabel" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="schoolLabel" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="gradeLabel" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="avatarLabel" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="nicknameLabel" />
                      </TableCell>
                      <TableCell align="right">
                        <FormattedMessage id="actionsLabel" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentsLeaderboardData.map((data) => {
                      const consents = data.consents?.flatMap((f) =>
                        f ? [f] : []
                      );

                      return (
                        <TableRow key={data.id}>
                          <TableCell>{concatUserName(data)}</TableCell>
                          <TableCell>
                            {
                              <Link href={`mailto:${data.email}`}>
                                {data.email}
                              </Link>
                            }
                          </TableCell>
                          <TableCell>
                            <StudentConsents consents={consents} />
                          </TableCell>
                          <TableCell>{data.school?.name}</TableCell>
                          <TableCell>{data.grade}</TableCell>
                          <TableCell>
                            <StudentAvatarManagement
                              data={data}
                              onUpdate={studentsQuery.refetch}
                            />
                          </TableCell>
                          <TableCell>
                            <StudentNicknameManagement
                              data={data}
                              onUpdate={studentsQuery.refetch}
                            />
                          </TableCell>
                          <TableCell width={120} align="right">
                            <IconButton
                              color="success"
                              onClick={() => {
                                setSelectedStudent(data);
                                setShowEditDialog(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => {
                                if (data.id) {
                                  setDeleteId(data.id);
                                }
                              }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {pageInfo?.hasNextPage && (
                <Button disabled={studentsQuery.loading} onClick={loadMore}>
                  <FormattedMessage id="loadMoreButton" />
                </Button>
              )}
            </Stack>
          </PageSection>
        </Page>
      </ProtectedRoute>
      <AlertDialog
        title={<FormattedMessage id="studentsDeleteDialogTitle" />}
        description={<FormattedMessage id="studentsDeleteDialogDescription" />}
        open={deleteId !== undefined}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
      />
      <StudentDialog
        open={showEditDialog}
        student={selectedStudent}
        onClose={handleCloseEditDialog}
        onUpdate={studentsQuery.refetch}
      />
    </>
  );
};

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { GET_QUESTION_CATEGORIES } from "../../graphql/QuestionCategory";
import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";

const Wrapper = styled(FormControl)`
  min-width: 150px;
`;

interface Props {
  categoryId: string;
  setCategoryId: (value: string) => void;
  size?: "small" | "medium" | undefined;
  showAllOption?: boolean;
}

export const CategorySelect = ({
  categoryId,
  setCategoryId,
  size = "small",
  showAllOption = true,
}: Props) => {
  const categoriesQuery = useQuery(GET_QUESTION_CATEGORIES);
  const categories = categoriesQuery.data?.questionCategories || [];

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    setCategoryId(event.target.value);
  };

  return (
    <Wrapper size={size}>
      <InputLabel>
        <FormattedMessage id="categoryLabel" />
      </InputLabel>
      <Select
        disabled={categoriesQuery.loading}
        value={categoryId}
        label={<FormattedMessage id="categoryLabel" />}
        onChange={handleCategoryChange}
      >
        {showAllOption && (
          <MenuItem value="">
            <FormattedMessage id="selectAllOption" />
          </MenuItem>
        )}
        {categories.map((category) =>
          category.id && category.name ? (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ) : null
        )}
      </Select>
    </Wrapper>
  );
};

import {
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DELETE_QUESTION, GET_QUESTIONS } from "../../graphql/Question";
import {
  DeleteQuestionStatus,
  EditorQuestionState,
  ImageEditorQuestion,
  TextEditorQuestion,
} from "../../__generated__/graphql";
import { ReactNode, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { AlertDialog } from "../../components/AlertDialog/AlertDialog";
import { CategorySelect } from "../../components/CategorySelect/CategorySelect";
import { CsvImportButton } from "../../components/CsvImportButton/CsvImportButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DifficultySelect } from "../../components/DifficultySelect/DifficultySelect";
import EditIcon from "@mui/icons-material/Edit";
import { FormattedMessage } from "react-intl";
import { GradeSelect } from "../../components/GradeSelect/GradeSelect";
import { Page } from "../../components/Page/Page";
import { PageSection } from "../../components/PageSection/PageSection";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";
import { QuestionStateChip } from "../../components/QuestionStateChip/QuestionStateChip";
import { QuestionTypeChip } from "../../components/QuestionTypeChip/QuestionTypeChip";
import { ROUTE } from "../../config/Routes";
import { logError } from "../../utils/Logger";
import styled from "@emotion/styled";
import { useAppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useSnackbarContext } from "../../context/SnackbarContext";

const FilterFormControl = styled(FormControl)`
  min-width: 150px;
`;

const Grade = styled(Chip)`
  margin: 0 2px;
`;

const SearchTextField = styled(TextField)`
  flex: 1;
`;

const getState = (value: string): EditorQuestionState | undefined => {
  switch (value) {
    case "Active":
      return EditorQuestionState.Active;
    case "Draft":
      return EditorQuestionState.Draft;
    case "Archived":
      return EditorQuestionState.Archived;
    default:
      return undefined;
  }
};

const getStateLabel = (value: string): ReactNode => {
  switch (value) {
    case "Active":
      return <FormattedMessage id="questionsStateActive" />;
    case "Draft":
      return <FormattedMessage id="questionsStateDraft" />;
    case "Archived":
      return <FormattedMessage id="questionsStateArchived" />;
    default:
      return value;
  }
};

export const Questions = () => {
  const navigate = useNavigate();
  const [categoryId, setCategoryId] = useState("");
  const [difficultyId, setDifficultyId] = useState("");
  const [grade, setGrade] = useState("");
  const [state, setState] = useState("Active");
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [deleteQuestionId, setDeleteQuestionId] = useState<string>();
  const { setEditQuestion } = useAppContext();
  const { showSnack } = useSnackbarContext();

  // Questions list

  const questionsQuery = useQuery(GET_QUESTIONS);

  const questions = (questionsQuery.data?.editorQuestions || []).flatMap((f) =>
    f ? [f] : []
  );

  useEffect(() => {
    const updateDebounceTerm = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);

    return () => clearTimeout(updateDebounceTerm);
  }, [searchText]);

  useEffect(() => {
    questionsQuery.refetch({
      category: categoryId.length > 0 ? categoryId : undefined,
      difficulty: difficultyId.length > 0 ? difficultyId : undefined,
      grade: grade.length > 0 ? parseInt(grade) : undefined,
      state: getState(state),
      search: debouncedSearchText.length > 2 ? debouncedSearchText : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, difficultyId, grade, state, debouncedSearchText]);

  // Add / Edit question

  const handleNewTextQuestion = () => {
    setEditQuestion(undefined);
    navigate(ROUTE.QUESTION_TEXT.PATH);
  };

  const handleNewImageQuestion = () => {
    setEditQuestion(undefined);
    navigate(ROUTE.QUESTION_IMAGE.PATH);
  };

  const handleEditQuestion = (
    question: TextEditorQuestion | ImageEditorQuestion
  ) => {
    setEditQuestion(question);
    if (question.__typename === "TextEditorQuestion") {
      navigate(ROUTE.QUESTION_TEXT.PATH);
    } else if (question.__typename === "ImageEditorQuestion") {
      navigate(ROUTE.QUESTION_IMAGE.PATH);
    }
  };

  // Delete question

  const [deleteQuestion, deleteStatus] = useMutation(DELETE_QUESTION, {
    onError: (error) => {
      showSnack(<FormattedMessage id="unknownError" />, "error");
      logError(error.message);
    },
    onCompleted: (data) => {
      if (data.deleteQuestion?.status === DeleteQuestionStatus.Success) {
        showSnack(<FormattedMessage id="questionsDeletedMessage" />, "success");
        questionsQuery.refetch();
      } else {
        showSnack(<FormattedMessage id="unknownError" />, "error");
      }
    },
  });

  const handleCloseDeleteDialog = () => {
    setDeleteQuestionId(undefined);
  };

  const handleConfirmDeleteQuestion = () => {
    if (deleteQuestionId) {
      deleteQuestion({ variables: { id: deleteQuestionId } });
    }
    setDeleteQuestionId(undefined);
  };

  // Filters

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    setState(event.target.value);
  };

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length > 100) return;
    setSearchText(event.target.value);
  };

  const handleFilterReset = () => {
    setCategoryId("");
    setDifficultyId("");
    setGrade("");
    setState("");
    setSearchText("");
  };

  return (
    <>
      <ProtectedRoute roles={ROUTE.QUESTIONS.ROLES}>
        <Page>
          <PageSection title={<FormattedMessage id="questionsAddLabel" />}>
            <Stack direction="row" spacing={1}>
              <Button onClick={handleNewTextQuestion} variant="contained">
                <FormattedMessage id="questionsAddTextButton" />
              </Button>
              <Button onClick={handleNewImageQuestion} variant="contained">
                <FormattedMessage id="questionsAddImageButton" />
              </Button>
              <CsvImportButton />
            </Stack>
          </PageSection>
          <PageSection title={<FormattedMessage id="filterLabel" />}>
            <Stack direction="row" spacing={1}>
              <CategorySelect
                categoryId={categoryId}
                setCategoryId={setCategoryId}
              />
              <DifficultySelect
                difficultyId={difficultyId}
                setDifficultyId={setDifficultyId}
              />
              <GradeSelect grade={grade} setGrade={setGrade} />
              <FilterFormControl size="small">
                <InputLabel>
                  <FormattedMessage id="questionsStateLabel" />
                </InputLabel>
                <Select
                  disabled={questionsQuery.loading}
                  value={state}
                  label={<FormattedMessage id="questionsStateLabel" />}
                  onChange={handleStateChange}
                >
                  <MenuItem value="">
                    <FormattedMessage id="selectAllOption" />
                  </MenuItem>
                  {Object.keys(EditorQuestionState).map((state) => (
                    <MenuItem key={state} value={state}>
                      {getStateLabel(state)}
                    </MenuItem>
                  ))}
                </Select>
              </FilterFormControl>
              <SearchTextField
                label="Hledat text"
                size="small"
                value={searchText}
                onChange={handleSearchTextChange}
              />
              <Button
                disabled={questionsQuery.loading}
                onClick={handleFilterReset}
              >
                <FormattedMessage id="resetFiltersButton" />
              </Button>
            </Stack>
          </PageSection>
          <PageSection>
            {(questionsQuery.loading || deleteStatus.loading) && (
              <LinearProgress />
            )}
            {!questionsQuery.loading && questions.length === 0 ? (
              <Typography variant="h6">
                <FormattedMessage id="emptyListMessage" />
              </Typography>
            ) : (
              <Stack spacing={2}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <FormattedMessage id="questionsStateLabel" />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id="questionsTypeLabel" />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id="questionsTextLabel" />
                        </TableCell>
                        <TableCell align="right">
                          <FormattedMessage id="questionsGradesLabel" />
                        </TableCell>
                        <TableCell align="right">
                          <FormattedMessage id="questionsDifficultyLabel" />
                        </TableCell>
                        <TableCell align="right">
                          <FormattedMessage id="questionsCategoryLabel" />
                        </TableCell>
                        <TableCell align="right">
                          <FormattedMessage id="questionsAnswersLabel" />
                        </TableCell>
                        <TableCell align="right">
                          <FormattedMessage id="actionsLabel" />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {questions.map((question) => {
                        const isImage =
                          question?.__typename === "ImageEditorQuestion";
                        const sortedGrades = [...question.grades].sort();
                        const questionState = question?.state;

                        return (
                          <TableRow key={question.id}>
                            <TableCell>
                              {questionState && (
                                <QuestionStateChip state={questionState} />
                              )}
                            </TableCell>
                            <TableCell>
                              <QuestionTypeChip
                                type={isImage ? "image" : "text"}
                              />
                            </TableCell>
                            <TableCell>{question.text}</TableCell>
                            <TableCell align="right">
                              {sortedGrades.map((grade) => (
                                <Grade key={grade} label={grade} />
                              ))}
                            </TableCell>
                            <TableCell align="right">
                              {question.difficulty?.name}
                            </TableCell>
                            <TableCell width={150} align="right">
                              {question.category?.name}
                            </TableCell>
                            <TableCell align="right">
                              {question.answers?.length}
                            </TableCell>
                            <TableCell width={120} align="right">
                              <IconButton
                                color="success"
                                onClick={() => {
                                  if (question) {
                                    handleEditQuestion(question);
                                  }
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => {
                                  if (question.id) {
                                    setDeleteQuestionId(question.id);
                                  }
                                }}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
          </PageSection>
        </Page>
      </ProtectedRoute>
      <AlertDialog
        title={<FormattedMessage id="questionsDeleteDialogTitle" />}
        description={<FormattedMessage id="questionsDeleteDialogDescription" />}
        open={deleteQuestionId !== undefined}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDeleteQuestion}
      />
    </>
  );
};

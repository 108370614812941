import { gql } from "../__generated__/gql";

export const GET_STUDENTS = gql(`
  query GetStudents( 
    $schoolId: ID,
    $grade: Int,
    $first: Int,
    $after: Int
  ) {
    students(
      schoolId: $schoolId,
      grade: $grade,
      first: $first,
      after: $after
    ) {
      edges {
        node {
          id
          firstName
          surname
          nickname
          email
          avatar
          school {
            id
            name
          }
          grade
          consents {
            name
            status
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const GET_STUDENTS_LEADERBOARD = gql(`
  query GetStudentsLeaderboard( 
    $school: ID,
    $grade: Int,
    $startDate: Date,
    $endDate: Date,
    $first: Int,
    $after: Int
  ) {
    studentsAdminLeaderboard(
        school: $school,
        grade: $grade,
        startDate: $startDate,
        endDate: $endDate,
        first: $first,
        after: $after
    ) {
      edges {
        node {
          student {
            id
            firstName
            surname
            nickname
            email
            avatar
            school {
              id
              name
            }
            grade
          }
          stats {
            points
            maxPoints
            completedQuizCount
            totalQuizCount
            accuracy
            schoolRank
            globalRank
            totalStudents
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const CREATE_STUDENT = gql(`
  mutation CreateStudent($input: CreateStudentInput!) {
    createStudent(input: $input) {
      student{
        id
      }
    }
  }
`);

export const UPDATE_STUDENT = gql(`
  mutation UpdateStudent($input: UpdateStudentInput!) {
    updateStudent(input: $input) {
      student {
        id
      }
    }
  }
`);

export const DELETE_STUDENT = gql(`
  mutation DeleteStudent($id: ID!) {
    deleteStudent(id: $id) {
      success
    }
  }
`);

import { ApolloError, useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  styled,
} from "@mui/material";
import { CREATE_SCHOOL, UPDATE_SCHOOL } from "../../graphql/Schools";
import { useEffect, useState } from "react";
import { DesignTokens } from "../../style/DesignTokens";
import { FormattedMessage } from "react-intl";
import { School } from "../../__generated__/graphql";
import { logError } from "../../utils/Logger";
import { useSnackbarContext } from "../../context/SnackbarContext";

const NameTextField = styled(TextField)`
  margin-top: ${DesignTokens.spacing.space16};
  min-width: 360px;
`;

interface Props {
  open: boolean;
  school?: School;
  onUpdate: () => void;
  onClose: () => void;
}

export const SchoolDialog = ({ open, school, onUpdate, onClose }: Props) => {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { showSnack } = useSnackbarContext();

  const mutationSuccess = () => {
    onUpdate();
    onClose();
  };

  const mutationError = (error: ApolloError) => {
    showSnack(<FormattedMessage id="unknownError" />, "error");
    logError(error.message);
  };

  const [createSchool, createStatus] = useMutation(CREATE_SCHOOL, {
    onError: mutationError,
    onCompleted: (data) => {
      if (data.createSchool.school?.id) {
        showSnack(<FormattedMessage id="schoolsDialogAddMessage" />, "success");
        mutationSuccess();
      } else {
        showSnack(<FormattedMessage id="unknownError" />, "error");
      }
    },
  });

  const [updateSchool, updateStatus] = useMutation(UPDATE_SCHOOL, {
    onError: mutationError,
    onCompleted: (data) => {
      if (data.updateSchool.school?.id) {
        showSnack(
          <FormattedMessage id="schoolsDialogUpdateMessage" />,
          "success"
        );
        mutationSuccess();
      } else {
        showSnack(<FormattedMessage id="unknownError" />, "error");
      }
    },
  });

  useEffect(() => {
    setIsLoading(createStatus.loading || updateStatus.loading);
  }, [createStatus, updateStatus]);

  useEffect(() => {
    if (school && school.name) {
      setName(school.name);
    } else {
      setName("");
    }
  }, [school, open]);

  const onConfirm = () => {
    if (school && school.id) {
      updateSchool({
        variables: {
          input: {
            id: school.id,
            name: name,
          },
        },
      });
    } else {
      createSchool({
        variables: {
          input: {
            name: name,
          },
        },
      });
    }
  };

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage
          id={school ? "schoolsDialogUpdateTitle" : "schoolsDialogAddTitle"}
        />
      </DialogTitle>
      <DialogContent>
        <NameTextField
          fullWidth
          autoComplete="off"
          size="small"
          inputProps={{ maxLength: 50 }}
          label={<FormattedMessage id="nameInput" />}
          value={name}
          onChange={handleNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>
          <FormattedMessage id="cancelButton" />
        </Button>
        <Button
          disabled={!open || name.length === 0 || isLoading}
          variant="contained"
          onClick={onConfirm}
          autoFocus
        >
          {isLoading ? (
            <FormattedMessage id="savingMessage" />
          ) : (
            <FormattedMessage
              id={
                school ? "schoolsDialogUpdateButton" : "schoolsDialogAddButton"
              }
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const APP_TEXTS = {
  // Labels
  rankLabel: "Pořadí",
  nameLabel: "Jméno",
  firstNameLabel: "Jméno",
  surnameLabel: "Příjmení",
  emailLabel: "E-mail",
  consentsLabel: "Souhlasy",
  studentsLabel: "Studenti",
  studentLabel: "Student",
  schoolLabel: "Škola",
  gradeLabel: "Ročník",
  pointsLabel: "Body",
  completedQuizzesLabel: "Dokončené testy",
  accuracyLabel: "Správnost",
  averageAccuracyLabel: "Průměr správnosti",
  averagePointsLabel: "Body průměrně",
  activeStudentsLabel: "Aktivní studenti",
  globalRankLabel: "Celkové pořadí",
  schoolRankLabel: "Pořadí ve škole",
  actionsLabel: "Akce",
  avatarLabel: "Avatar",
  nicknameLabel: "Přezdívka",
  filterLabel: "Filtr",
  startLabel: "Začátek",
  endLabel: "Konec",
  categoryLabel: "Kategorie",
  difficultyLabel: "Náročnost",
  adminLabel: "Admin",
  teacherLabel: "Učitel",

  // Buttons
  yesButton: "Ano",
  noButton: "Ne",
  cancelButton: "Zrušit",
  loadMoreButton: "Načíst další",
  resetFiltersButton: "Zrušit filtry",

  // Inputs
  nameInput: "Jméno",

  // Selects
  selectAllOption: "Vše",
  schoolSelect: "Škola",

  // Messages
  savingMessage: "Ukládám",
  loadingMessage: "Načítám",
  emptyListMessage: "Žádné položky",

  // Errors
  unknownError: "Neznámá chyba",
  invalidEmail: "Neplatný e-mail",

  // Navigation
  navigationDashboard: "Nástěnka",
  navigationSchools: "Školy",
  navigationTeachers: "Učitelé",
  navigationStudents: "Studenti",

  navigationSectionQuestions: "Otázky",
  navigationQuestions: "Otázky",
  navigationCategories: "Kategorie",
  navigationDifficulty: "Náročnost",

  navigationSectionStats: "Statistiky",
  navigationStatsStudents: "Studenti",
  navigationStatsShools: "Školy",
  navigationStatsQuestion: "Otázky",
  navigationStatsTests: "Zásoba",
  navigationLogout: "Odhlásit",

  // Login
  loginButton: "Přihlásit se",
  loginFailedMessage: "Přihlášení se nezdařilo, zkuste to prosím znovu.",

  // Dashboard
  dashboardStudentsTitle: "Studenti",
  dashboardSchoolsTitle: "Školy",
  dashboardMoreSchoolStats: "Více školních statistik",
  dashboardMoreStudentStats: "Více studentských statistik",

  // Schools
  schoolsAddButton: "Přidat školu",

  schoolsDialogAddTitle: "Přidat školu",
  schoolsDialogAddButton: "Přidat",
  schoolsDialogAddMessage: "Škola byla přidána",

  schoolsDialogUpdateTitle: "Upravit školu",
  schoolsDialogUpdateButton: "Upravit",
  schoolsDialogUpdateMessage: "Škola byla upravena",

  schoolsDeleteDialogTitle: "Smazat školu",
  schoolsDeleteDialogDescription: "Jste si jistí, že chcete tuto školu zmazat?",
  schoolsDeletedMessage: "Škola byla smazána",

  // Teachers
  teachersAddButton: "Přidat učitele",

  teachersDialogAddTitle: "Přidat učitele",
  teachersDialogAddButton: "Přidat",
  teachersDialogAddMessage: "Učitel byl přidán",

  teachersDialogUpdateTitle: "Upravit učitele",
  teachersDialogUpdateButton: "Upravit",
  teachersDialogUpdateMessage: "Učitel byl upraven",

  teachersDeleteDialogTitle: "Smazat učitele",
  teachersDeleteDialogDescription:
    "Jste si jistí, že chcete tohoto učitele smazat?",
  teachersDeletedMessage: "Učitel byl smazán",
  teacherEmailChangeWarning:
    "Změna emailu učitele není podporována. Smažte stávajícího učitele a vytvořte nového se správným e-mailem.",

  // Students
  studentsAddButton: "Přidat studenta",
  studentsImportCsvButton: "Importovat CSV",
  studentsExportCsvButton: "Exportovat CSV",

  studentsDialogAddTitle: "Přidat studenta",
  studentsDialogAddButton: "Přidat",
  studentsDialogAddMessage: "Student byl přidán",

  studentsDialogUpdateTitle: "Upravit studenta",
  studentsDialogUpdateButton: "Upravit",
  studentsDialogUpdateMessage: "Student byl upraven",

  studentsDeleteDialogTitle: "Smazat studenta",
  studentsDeleteDialogDescription:
    "Jste si jistí, že chcete tohoto studenta smazat?",
  studentsDeletedMessage: "Student byl smazán",

  studentsDeleteAvatarDialogTitle: "Smazat avatara studenta",
  studentsDeleteAvatarDialogDescription:
    "Jste si jistí, že chcete smazat avatara tohoto studenta?",
  studentsDeletedAvatarMessage: "Avatar studenta byl smazán",

  studentsDeleteNicknameDialogTitle: "Smazat přezdívku studenta",
  studentsDeleteNicknameDialogDescription:
    "Jste si jistí, že chcete smazat přezdívku tohoto studenta?",
  studentsDeletedNicknameMessage: "Přezdívka studenta byla smazána",
  studentEmailChangeWarning0:
    "Změna emailu studenta není podporována. Pokud student nedokončil registraci a nebo není v aplikaci aktivní, můžete stávajícího studenta smazat a vytvořit nového se správným emailem.",
  studentEmailChangeWarning1:
    "V případě že je již student v aplikaci aktivní, požádejte podporu o migraci studenta na nový email.",

  // Questions
  questionsAddLabel: "Přidat otázku",
  questionsAddTextButton: "Textovou",
  questionsAddImageButton: "S obrázkem",
  questionsImportCsvButton: "Importovat CSV",

  questionsStateLabel: "Stav",
  questionsTypeLabel: "Typ",
  questionsTextLabel: "Text otázky",
  questionsGradesLabel: "Ročníky",
  questionsDifficultyLabel: "Náročnost",
  questionsCategoryLabel: "Kategorie",
  questionsAnswersLabel: "Odpovědi",

  questionsStateActive: "Aktivní",
  questionsStateDraft: "Koncept",
  questionsStateArchived: "Archivované",

  questionsQuestionTypeText: "Textová",
  questionsQuestionTypeImage: "S obrázkem",

  questionsDeleteDialogTitle: "Smazat otázku",
  questionsDeleteDialogDescription:
    "Jste si jistí, že chcete tuto otázku smazat?",
  questionsDeletedMessage: "Otázka byla smazána",

  // Question editor
  questionEditorGradesLabel: "Ročníky",
  questionEditorTextLabel: "Text otázky",

  questionEditorImageLabel: "Obrázek (formát .jpg)",
  questionEditorImageUploadButton: "Nahrát obrázek",

  questionEditorAnswersLabel: "Odpovědi",
  questionEditorAnswersCorrectLabel: "Správná",
  questionEditorAnswersTextLabel: "Text odpovědi",
  questionEditorAnswersAddButton: "Přidat odpověď",

  questionEditorAnswersDeleteDialogTitle: "Smazat odpověď",
  questionEditorAnswersDeleteDialogDescription:
    "Jste si jistí, že chcete tuto odpověď smazat?",

  questionEditorSavePublichButton: "Uložit a zveřejnit",
  questionEditorSaveDraftButton: "Uložit jako koncept",

  questionEditorCancelDialogTitle: "Zrušit změny",
  questionEditorCancelDialogDescription: "Opravdu chcete zrušit změny?",

  questionEditorImageUploadError: "Nahrávání obrázku se nezdařilo",
  questionEditorAddedMessage: "Otázka byla přidána",
  questionEditorUpdatedMessage: "Otázka byla upravena",

  // Question category
  questionCategoryAddButton: "Přidat kategorii",

  questionCategoryDialogAddTitle: "Přidat kategorii",
  questionCategoryDialogAddButton: "Přidat",
  questionCategoryDialogAddMessage: "Kategorie byla přidána",

  questionCategoryDialogNotFoundMessage: "Kategorie nebyla nalezena",

  questionCategoryDialogUpdateTitle: "Upravit kategorii",
  questionCategoryDialogUpdateButton: "Upravit",
  questionCategoryDialogUpdateMessage: "Kategorie byla upravena",

  questionCategoryDeleteDialogTitle: "Smazat kategorii",
  questionCategoryDeleteDialogDescription:
    "Opravdu chcete tuto kategorii smazat?",
  questionCategoryDeletedMessage: "Kategorie byla smazána",

  // Question difficulty
  questionDifficultyAddButton: "Přidat úroveň náročnosti",
  questionDifficultyCountInQuizLabel: "Počet v testu",

  questionDifficultyDialogNotFoundMessage: "Úroveň náročnosti nebyla nalezena",

  questionDifficultyDialogAddTitle: "Přidat náročnost",
  questionDifficultyDialogAddButton: "Přidat",
  questionDifficultyDialogAddMessage: "Náročnost byla přidána",

  questionDifficultyDialogUpdateTitle: "Upravit náročnost",
  questionDifficultyDialogUpdateButton: "Upravit",
  questionDifficultyDialogUpdateMessage: "Náročnost byla upravena",

  questionDifficultyDeleteDialogTitle: "Smazat náročnost",
  questionDifficultyDeleteDialogDescription:
    "Opravdu chcete tuto úroveň náročnosti zmazat?",
  questionDifficultyDeletedMessage: "Náročnost byla smazána",

  // Questions stats
  questionsStatsQuestionLabel: "Otázka",
  questionsStatsMaxPointsLabel: "Maximum bodů",
  questionsStatsAveragePointsLabel: "Body průměrně",
  questionsStatsTimeLimitLabel: "Čas na přečtení / celkový čas",
  questionsStatsAverageTimeLabel: "Průměrný čas",
  questionsStatsSuccessRateLabel: "Úspěšnost",
  questionsStatsTotalAnswersLabel: "Celkem odpovědí",
  questionsStatsCorrectAnswersLabel: "Správných odpovědí",
  questionsStatsWrongAnswersLabel: "Špatných odpovědí",
  questionsStatsMaxPointsAnswersLabel: "Studenti s maximum body",
  questionsStatsTimeoutsLabel: "Nestihlo",

  // Tests stats
  testsStatsGeneratedTestsLabel: "Počet vygenerovaných testů",
  testsStatsGradeLabel: ". ročník",
  testsStatsDifficultyLabel: "Náročnost",
  testsStatsUsedQuestionsLabel: "Použitých otázek",
  testsStatsRemainingQuestionsLabel: "Zbývá otázek",
  testsStatsCanGenerateTestsLabel: "Lze vygenerovat testů",

  // Consents
  consentContactLabel: "Kontakt",
};

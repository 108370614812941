import { format, formatISO, isValid } from "date-fns";

export const INPUT_DATE_FORMAT = "dd/MM/yyyy";

export const msToTimeString = (milliseconds: number | null | undefined) => {
  return milliseconds != null ? format(milliseconds, "m:ss") : "--:--";
};

export const getIsoDate = (date: Date) => {
  return formatISO(date, { representation: "date" });
};

export const validateDateChange = (newValue: string | null) => {
  if (newValue == null) {
    return null;
  }
  const date = new Date(newValue);
  return isValid(date) ? date : null;
};

const emailRegex = /\S+@\S+\.\S+/;

export const concatUserName = (userData: {
  firstName?: string | null;
  surname?: string | null;
}): string => {
  return `${userData.surname} ${userData.firstName}`;
};

export const isEmailValid = (email: string): boolean => {
  return emailRegex.test(email);
};

import { Navigate, useLocation } from "react-router-dom";
import { AuthRole } from "../../config/Enums";
import { ROUTE } from "../../config/Routes";
import { useAppContext } from "../../context/AppContext";
import { useIsAuthenticated } from "@azure/msal-react";

interface Props {
  roles: AuthRole[];
  children: React.ReactNode;
}

export const ProtectedRoute = ({ children, roles }: Props) => {
  const isAuthenticated = useIsAuthenticated();
  const { userRole } = useAppContext();
  const location = useLocation();

  if (!isAuthenticated || userRole === undefined || !roles.includes(userRole)) {
    return <Navigate to={ROUTE.ROOT.PATH} replace state={{ from: location }} />;
  }

  return <>{children}</>;
};

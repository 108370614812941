import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { APP_TEXTS } from "../../localization/Localization";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ApolloService } from "../../services/ApolloService";
import { AppContextProvider } from "../../context/AppContextProvider";
import { Dashboard } from "../../routes/Dashboard/Dashboard";
import { IntlProvider } from "react-intl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Login } from "../../routes/Login/Login";
import { MsalService } from "../../services/MsalService";
import { Navigation } from "../Navigation/Navigation";
import { QuestionCategories } from "../../routes/QuestionCategories/QuestionCategories";
import { QuestionDifficulty } from "../../routes/QuestionDifficulty/QuestionDifficulty";
import { QuestionImage } from "../../routes/QuestionImage/QuestionImage";
import { QuestionStats } from "../../routes/QuestionStats/QuestionStats";
import { QuestionText } from "../../routes/QuestionText/QuestionText";
import { Questions } from "../../routes/Questions/Questions";
import { ROUTE } from "../../config/Routes";
import { Schools } from "../../routes/Schools/Schools";
import { SchoolsStats } from "../../routes/SchoolsStats/SchoolsStats";
import { SnackbarContextProvider } from "../../context/SnackbarContextProvider";
import { Students } from "../../routes/Students/Students";
import { StudentsStats } from "../../routes/StudentsStats/StudentsStats";
import { Teachers } from "../../routes/Teachers/Teachers";
import { TestsStats } from "../../routes/TestsStats/TestsStats";
import { cs } from "date-fns/locale";
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
`;

export const App = () => {
  return (
    <IntlProvider messages={APP_TEXTS} locale="cs" defaultLocale="cs">
      <MsalService>
        <ApolloService>
          <LocalizationProvider adapterLocale={cs} dateAdapter={AdapterDateFns}>
            <AppContextProvider>
              <SnackbarContextProvider>
                <BrowserRouter>
                  <Container>
                    <Navigation />
                    <Routes>
                      <Route path={ROUTE.ROOT.PATH} element={<Login />} />
                      <Route
                        path={ROUTE.DASHBOARD.PATH}
                        element={<Dashboard />}
                      />
                      <Route path={ROUTE.SCHOOLS.PATH} element={<Schools />} />
                      <Route
                        path={ROUTE.TEACHERS.PATH}
                        element={<Teachers />}
                      />
                      <Route
                        path={ROUTE.STUDENTS.PATH}
                        element={<Students />}
                      />
                      <Route
                        path={ROUTE.QUESTIONS.PATH}
                        element={<Questions />}
                      />
                      <Route
                        path={ROUTE.QUESTION_TEXT.PATH}
                        element={<QuestionText />}
                      />
                      <Route
                        path={ROUTE.QUESTION_IMAGE.PATH}
                        element={<QuestionImage />}
                      />
                      <Route
                        path={ROUTE.QUESTION_CATEGORIES.PATH}
                        element={<QuestionCategories />}
                      />
                      <Route
                        path={ROUTE.QUESTION_DIFFICULTY.PATH}
                        element={<QuestionDifficulty />}
                      />
                      <Route
                        path={ROUTE.STUDENTS_STATS.PATH}
                        element={<StudentsStats />}
                      />
                      <Route
                        path={ROUTE.SCHOOLS_STATS.PATH}
                        element={<SchoolsStats />}
                      />
                      <Route
                        path={ROUTE.QUESTION_STATS.PATH}
                        element={<QuestionStats />}
                      />
                      <Route
                        path={ROUTE.TESTS_STATS.PATH}
                        element={<TestsStats />}
                      />
                      <Route
                        path="*"
                        element={<Navigate to={ROUTE.ROOT.PATH} replace />}
                      />
                    </Routes>
                  </Container>
                </BrowserRouter>
              </SnackbarContextProvider>
            </AppContextProvider>
          </LocalizationProvider>
        </ApolloService>
      </MsalService>
    </IntlProvider>
  );
};

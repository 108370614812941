import { ApolloError, useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  styled,
} from "@mui/material";
import {
  CREATE_QUESTION_CATEGORY,
  UPDATE_QUESTION_CATEGORY,
} from "../../graphql/QuestionCategory";
import {
  QuestionCategory,
  UpdateQuestionCategoryStatus,
} from "../../__generated__/graphql";
import { useEffect, useState } from "react";
import { DesignTokens } from "../../style/DesignTokens";
import { FormattedMessage } from "react-intl";
import { logError } from "../../utils/Logger";
import { useSnackbarContext } from "../../context/SnackbarContext";

const NameTextField = styled(TextField)`
  margin-top: ${DesignTokens.spacing.space16};
`;

interface Props {
  open: boolean;
  category?: QuestionCategory;
  onUpdate: () => void;
  onClose: () => void;
}

export const QuestionCategoryDialog = ({
  open,
  category,
  onUpdate,
  onClose,
}: Props) => {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { showSnack } = useSnackbarContext();

  const mutationSuccess = () => {
    onUpdate();
    onClose();
  };

  const mutationError = (error: ApolloError) => {
    showSnack(<FormattedMessage id="unknownError" />, "error");
    logError(error.message);
  };

  const [createQuestionCategory, createStatus] = useMutation(
    CREATE_QUESTION_CATEGORY,
    {
      onError: mutationError,
      onCompleted: (data) => {
        if (data.createQuestionCategory.category?.id) {
          showSnack(
            <FormattedMessage id="questionCategoryDialogAddMessage" />,
            "success"
          );
          mutationSuccess();
        } else {
          showSnack(<FormattedMessage id="unknownError" />, "error");
        }
      },
    }
  );

  const [updateQuestionCategory, updateStatus] = useMutation(
    UPDATE_QUESTION_CATEGORY,
    {
      onError: mutationError,
      onCompleted: (data) => {
        switch (data.updateQuestionCategory?.status) {
          case UpdateQuestionCategoryStatus.Success:
            showSnack(
              <FormattedMessage id="questionCategoryDialogUpdateMessage" />,
              "success"
            );
            mutationSuccess();
            break;
          case UpdateQuestionCategoryStatus.ErrorCategoryNotFound:
            showSnack(
              <FormattedMessage id="questionCategoryDialogNotFoundMessage" />,
              "error"
            );
            break;
          default:
            showSnack(<FormattedMessage id="unknownError" />, "error");
            break;
        }
      },
    }
  );

  useEffect(() => {
    setIsLoading(createStatus.loading || updateStatus.loading);
  }, [createStatus, updateStatus]);

  useEffect(() => {
    if (category && category.name) {
      setName(category.name);
    } else {
      setName("");
    }
  }, [category, open]);

  const onConfirm = () => {
    if (category && category.id) {
      updateQuestionCategory({
        variables: {
          input: {
            id: category.id,
            name: name,
          },
        },
      });
    } else {
      createQuestionCategory({
        variables: {
          input: {
            name: name,
          },
        },
      });
    }
  };

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage
          id={
            category
              ? "questionCategoryDialogUpdateTitle"
              : "questionCategoryDialogAddTitle"
          }
        />
      </DialogTitle>
      <DialogContent>
        <NameTextField
          fullWidth
          autoComplete="off"
          inputProps={{ maxLength: 30 }}
          label={<FormattedMessage id="nameLabel" />}
          value={name}
          onChange={handleNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>
          <FormattedMessage id="cancelButton" />
        </Button>
        <Button
          disabled={!open || name.length === 0 || isLoading}
          variant="contained"
          onClick={onConfirm}
          autoFocus
        >
          {isLoading ? (
            <FormattedMessage id="savingMessage" />
          ) : (
            <FormattedMessage
              id={
                category
                  ? "questionCategoryDialogUpdateButton"
                  : "questionCategoryDialogAddButton"
              }
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

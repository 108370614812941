import {
  ImageEditorQuestion,
  Teacher,
  TextEditorQuestion,
} from "../__generated__/graphql";
import { ReactNode, useEffect, useState } from "react";
import { AppContext } from "./AppContext";
import { AuthRole } from "../config/Enums";
import { GET_USER } from "../graphql/User";
import { logError } from "../utils/Logger";
import { useLazyQuery } from "@apollo/client";
import { useMsal } from "@azure/msal-react";

interface Props {
  children: ReactNode;
}

export const AppContextProvider = ({ children }: Props) => {
  const [userRole, setUserRole] = useState<AuthRole | undefined>();
  const [userEmail, setUserEmail] = useState<string | undefined>();
  const [teacher, setTeacher] = useState<Teacher | undefined>();
  const [editQuestion, setEditQuestion] = useState<
    TextEditorQuestion | ImageEditorQuestion | undefined
  >();
  const { accounts } = useMsal();

  // ==============================================
  // User
  // ==============================================

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      setUserEmail(data.me?.email || undefined);
      setTeacher(data.me?.__typename === "Teacher" ? data.me : undefined);
    },
    onError: (error) => {
      logError(error.message);
    },
  });

  useEffect(() => {
    const account = accounts.length > 0 ? accounts[0] : null;
    if (account) {
      switch (account.idTokenClaims?.extension_Role) {
        case "ADMIN":
          setUserRole(AuthRole.ADMIN);
          getUser();
          break;
        case "TEACHER":
          setUserRole(AuthRole.TEACHER);
          getUser();
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  // ==============================================
  // Render
  // ==============================================

  return (
    <AppContext.Provider
      value={{
        userRole,
        userEmail,
        teacher,
        editQuestion,
        setEditQuestion,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

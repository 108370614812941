import { Chip } from "@mui/material";
import { EditorQuestionState } from "../../__generated__/graphql";
import { FormattedMessage } from "react-intl";

interface Props {
  state: EditorQuestionState;
}

export const QuestionStateChip = ({ state }: Props) => {
  switch (state) {
    case EditorQuestionState.Draft:
      return (
        <Chip
          color="info"
          label={<FormattedMessage id="questionsStateDraft" />}
        />
      );
    case EditorQuestionState.Archived:
      return <Chip label={<FormattedMessage id="questionsStateArchived" />} />;
    case EditorQuestionState.Active:
      return (
        <Chip
          color="success"
          label={<FormattedMessage id="questionsStateActive" />}
        />
      );
  }
};

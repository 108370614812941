import { Chip } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface Props {
  type: "text" | "image";
}

export const QuestionTypeChip = ({ type }: Props) => {
  return (
    <Chip
      color={type === "text" ? "secondary" : "success"}
      label={
        <FormattedMessage
          id={
            type === "text"
              ? "questionsQuestionTypeText"
              : "questionsQuestionTypeImage"
          }
        />
      }
    />
  );
};

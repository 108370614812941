import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { GET_QUESTION_DIFFUCULTY_LEVELS } from "../../graphql/QuestionDifficulty";
import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";

const Wrapper = styled(FormControl)`
  min-width: 150px;
`;

interface Props {
  difficultyId: string;
  setDifficultyId: (value: string) => void;
  size?: "small" | "medium" | undefined;
  showAllOption?: boolean;
}

export const DifficultySelect = ({
  difficultyId,
  setDifficultyId,
  size = "small",
  showAllOption = true,
}: Props) => {
  const difficultyQuery = useQuery(GET_QUESTION_DIFFUCULTY_LEVELS);
  const difficultyLevels = difficultyQuery.data?.questionDifficultyLevels || [];

  const handleDifficultyChange = (event: SelectChangeEvent<string>) => {
    setDifficultyId(event.target.value);
  };

  return (
    <Wrapper size={size}>
      <InputLabel>
        <FormattedMessage id="difficultyLabel" />
      </InputLabel>
      <Select
        disabled={difficultyQuery.loading}
        value={difficultyId}
        label={<FormattedMessage id="difficultyLabel" />}
        onChange={handleDifficultyChange}
      >
        {showAllOption && (
          <MenuItem value="">
            <FormattedMessage id="selectAllOption" />
          </MenuItem>
        )}
        {difficultyLevels.map((difficulty) =>
          difficulty.id && difficulty.name && difficulty.points ? (
            <MenuItem key={difficulty.id} value={difficulty.id}>
              {difficulty.name}
            </MenuItem>
          ) : null
        )}
      </Select>
    </Wrapper>
  );
};

import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  TextField,
  styled,
} from "@mui/material";
import { AlertDialog } from "../../components/AlertDialog/AlertDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DesignTokens } from "../../style/DesignTokens";
import { FormattedMessage } from "react-intl";
import { PageSection } from "../PageSection/PageSection";
import { QuestionAnswer } from "../../mock/MockTypes";
import { useState } from "react";

const AnswerWrapper = styled(Paper)`
  padding: ${DesignTokens.spacing.space8};
`;

const AnswerDeleteButton = styled(IconButton)`
  width: 40px;
  height: 40px;
`;

interface Props {
  answers: QuestionAnswer[];
  setAnswers: (answers: QuestionAnswer[]) => void;
}

export const QuestionEditorAnswers = ({ answers, setAnswers }: Props) => {
  const [deleteAnswerIndex, setDeleteAnswerIndex] = useState<number | null>(
    null
  );

  const handleDeleteAnswer = (answerIndex: number) => {
    setDeleteAnswerIndex(answerIndex);
  };

  const handleCloseAnswerDeleteDialog = () => {
    setDeleteAnswerIndex(null);
  };

  const handleAddAnswer = () => {
    setAnswers([
      ...answers,
      {
        text: "",
        correct: false,
      },
    ]);
  };

  const handleConfirmAnswerDelete = () => {
    setAnswers(answers.filter((_, index) => index !== deleteAnswerIndex));
    setDeleteAnswerIndex(null);
  };

  const handleAnswerChange =
    (answerIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newAnswers = [...answers];
      newAnswers[answerIndex].text = event.target.value;
      setAnswers(newAnswers);
    };

  const handleCorrectChange =
    (answerIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newAnswers = [...answers];
      newAnswers[answerIndex].correct = event.target.checked;
      setAnswers(newAnswers);
    };

  return (
    <>
      <PageSection title={<FormattedMessage id="questionEditorAnswersLabel" />}>
        <Stack spacing={2}>
          {answers.map((answer, index) => (
            <AnswerWrapper key={index} variant="outlined">
              <Stack spacing={2} direction="row" alignItems="center">
                <TextField
                  fullWidth
                  multiline
                  label={
                    <FormattedMessage id="questionEditorAnswersTextLabel" />
                  }
                  value={answer.text}
                  onChange={handleAnswerChange(index)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="success"
                      checked={answer.correct}
                      onChange={handleCorrectChange(index)}
                    />
                  }
                  label={
                    <FormattedMessage id="questionEditorAnswersCorrectLabel" />
                  }
                />
                <AnswerDeleteButton
                  color="error"
                  onClick={() => {
                    handleDeleteAnswer(index);
                  }}
                >
                  <DeleteForeverIcon />
                </AnswerDeleteButton>
              </Stack>
            </AnswerWrapper>
          ))}
          <div>
            <Button variant="contained" onClick={handleAddAnswer}>
              <FormattedMessage id="questionEditorAnswersAddButton" />
            </Button>
          </div>
        </Stack>
      </PageSection>
      <AlertDialog
        title={<FormattedMessage id="questionEditorAnswersDeleteDialogTitle" />}
        description={
          <FormattedMessage id="questionEditorAnswersDeleteDialogDescription" />
        }
        open={deleteAnswerIndex !== null}
        onClose={handleCloseAnswerDeleteDialog}
        onConfirm={handleConfirmAnswerDelete}
      />
    </>
  );
};

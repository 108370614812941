import { ApolloError, useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { CREATE_TEACHER, UPDATE_TEACHER } from "../../graphql/Teachers";
import { useEffect, useState } from "react";
import { DesignTokens } from "../../style/DesignTokens";
import { FormattedMessage } from "react-intl";
import { SchoolSelect } from "../SchoolSelect/SchoolSelect";
import { Teacher } from "../../__generated__/graphql";
import { isEmailValid } from "../../utils/UserUtils";
import { logError } from "../../utils/Logger";
import { useSnackbarContext } from "../../context/SnackbarContext";

const ContentStack = styled(Stack)`
  margin-top: ${DesignTokens.spacing.space8};
  min-width: 360px;
`;

interface Props {
  open: boolean;
  teacher?: Teacher;
  onUpdate: () => void;
  onClose: () => void;
}

export const TeacherDialog = ({ open, teacher, onUpdate, onClose }: Props) => {
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const { showSnack } = useSnackbarContext();

  const mutationSuccess = () => {
    onUpdate();
    onClose();
  };

  const mutationError = (error: ApolloError) => {
    showSnack(<FormattedMessage id="unknownError" />, "error");
    logError(error.message);
  };

  const [createTeacher, createStatus] = useMutation(CREATE_TEACHER, {
    onError: mutationError,
    onCompleted: (data) => {
      if (data.createTeacher.teacher?.id) {
        showSnack(
          <FormattedMessage id="teachersDialogAddMessage" />,
          "success"
        );
        mutationSuccess();
      } else {
        showSnack(<FormattedMessage id="unknownError" />, "error");
      }
    },
  });

  const [updateTeacher, updateStatus] = useMutation(UPDATE_TEACHER, {
    onError: mutationError,
    onCompleted: (data) => {
      if (data.updateTeacher.teacher?.id) {
        showSnack(
          <FormattedMessage id="teachersDialogUpdateMessage" />,
          "success"
        );
        mutationSuccess();
      } else {
        showSnack(<FormattedMessage id="unknownError" />, "error");
      }
    },
  });

  useEffect(() => {
    setIsLoading(createStatus.loading || updateStatus.loading);
  }, [createStatus, updateStatus]);

  useEffect(() => {
    if (
      teacher &&
      teacher.firstName &&
      teacher.surname &&
      teacher.school?.id &&
      teacher.email
    ) {
      setFirstName(teacher.firstName);
      setSurname(teacher.surname);
      setSchoolId(teacher.school.id);
      setEmail(teacher.email);
    } else {
      setFirstName("");
      setSurname("");
      setSchoolId("");
      setEmail("");
    }
  }, [teacher, open]);

  const onConfirm = () => {
    if (!isEmailValid(email)) {
      showSnack(<FormattedMessage id="invalidEmail" />, "error");
      return;
    }

    if (teacher && teacher.id) {
      updateTeacher({
        variables: {
          input: {
            id: teacher.id,
            firstName: firstName,
            surname: surname,
            schoolId: schoolId,
            email: email,
          },
        },
      });
    } else {
      createTeacher({
        variables: {
          input: {
            firstName: firstName,
            surname: surname,
            schoolId: schoolId,
            email: email,
          },
        },
      });
    }
  };

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };

  const handleSurnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSurname(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage
          id={teacher ? "teachersDialogUpdateTitle" : "teachersDialogAddTitle"}
        />
      </DialogTitle>
      <DialogContent>
        <ContentStack spacing={2}>
          <SchoolSelect
            showAllOption={false}
            schoolId={schoolId}
            setSchoolId={setSchoolId}
          />
          <TextField
            fullWidth
            autoComplete="off"
            size="small"
            inputProps={{ maxLength: 30 }}
            label={<FormattedMessage id="firstNameLabel" />}
            value={firstName}
            onChange={handleFirstNameChange}
          />
          <TextField
            fullWidth
            autoComplete="off"
            size="small"
            inputProps={{ maxLength: 30 }}
            label={<FormattedMessage id="surnameLabel" />}
            value={surname}
            onChange={handleSurnameChange}
          />
          <TextField
            fullWidth
            autoComplete="off"
            size="small"
            inputProps={{ maxLength: 254 }}
            label={<FormattedMessage id="emailLabel" />}
            type="email"
            value={email}
            disabled={!!teacher}
            onChange={handleEmailChange}
          />
          {!!teacher && (
            <Typography variant="caption" color={DesignTokens.colors.orange}>
              <FormattedMessage id="teacherEmailChangeWarning" />
            </Typography>
          )}
        </ContentStack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>
          <FormattedMessage id="cancelButton" />
        </Button>
        <Button
          disabled={
            !open ||
            firstName.length === 0 ||
            surname.length === 0 ||
            schoolId.length === 0 ||
            email.length === 0 ||
            isLoading
          }
          variant="contained"
          onClick={onConfirm}
          autoFocus
        >
          {isLoading ? (
            <FormattedMessage id="savingMessage" />
          ) : (
            <FormattedMessage
              id={
                teacher
                  ? "teachersDialogUpdateButton"
                  : "teachersDialogAddButton"
              }
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

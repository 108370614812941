import { DesignTokens } from "../../style/DesignTokens";
import { Typography } from "@mui/material";

interface Props {
  value: number | null | undefined;
}

export const TestsYearStatsNumber = ({ value }: Props) => {
  if (value === null || value === undefined) {
    return (
      <Typography color={DesignTokens.colors.red} variant="h6">
        N/A
      </Typography>
    );
  }

  if (value === 0) {
    return (
      <Typography color={DesignTokens.colors.red} variant="h6">
        {value}
      </Typography>
    );
  }

  if (value < 3) {
    return (
      <Typography color={DesignTokens.colors.orange} variant="h6">
        {value}
      </Typography>
    );
  }

  return (
    <Typography color={DesignTokens.colors.green} variant="h6">
      {value}
    </Typography>
  );
};

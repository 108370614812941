import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "../config/AuthConfig";
import { relayStylePagination } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { useMsal } from "@azure/msal-react";

interface Props {
  children: React.ReactNode;
}

export const ApolloService = ({ children }: Props) => {
  const { instance, accounts, inProgress } = useMsal();

  const acquireToken = async () => {
    const account = accounts.length > 0 ? accounts[0] : null;
    if (account && inProgress === InteractionStatus.None) {
      try {
        const result = await instance.acquireTokenSilent({
          ...loginRequest,
          account,
        });
        return result.accessToken;
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect(loginRequest);
        }
      }
    } else if (!account && inProgress === InteractionStatus.None) {
      return instance.acquireTokenRedirect(loginRequest);
    }
    return null;
  };

  const withHeaders = setContext(async (_, { headers }) => {
    const token = await acquireToken();
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
        "x-request-timestamp": Date.now(),
      },
    };
  });

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_SERVER_URI}/graphql`,
  });

  const client = new ApolloClient({
    link: from([withHeaders, httpLink]),
    connectToDevTools: true,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            students: relayStylePagination(),
            studentsAdminLeaderboard: relayStylePagination(),
            teachers: relayStylePagination(),
          },
        },
      },
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

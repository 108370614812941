import React, { ReactNode, useContext } from "react";
import { AlertColor } from "@mui/material";

interface SnackbarContextInterface {
  showSnack: (message: ReactNode, severity: AlertColor) => void;
}

export const SnackbarContext = React.createContext<SnackbarContextInterface>({
  showSnack: () => {},
});

export const useSnackbarContext = () => {
  return useContext(SnackbarContext);
};

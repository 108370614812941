import { gql } from "../__generated__/gql";

export const GET_DASHBOARD_DATA = gql(`
  query GetDashboardData( 
    $startDate: Date,
    $endDate: Date,
    $studentsLimit: Int,
  ) {
    schoolsAdminLeaderboard(
        startDate: $startDate,
        endDate: $endDate,
    ) {
        school {
            id
            name
        }
        rank
        studentsCount
        activeStudentsCount
        points
        averageAccuracy
    }
    studentsAdminLeaderboard(
        startDate: $startDate,
        endDate: $endDate, 
        first: $studentsLimit,
    ) {
      edges {
        node {
          student {
            id
            firstName
            surname
            school {
              id
              name
            }
            grade
          }
          stats {
            points
            accuracy
            schoolRank
            globalRank
          }
        }
      }
    }
  }
`);

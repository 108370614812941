import { gql } from "../__generated__/gql";

export const GET_QUIZ_EVENT_COUNT = gql(`
    query QuizEventCount {
        quizEventCount
    }
`);

export const GET_QUIZ_EVENT_AVAILABLE_COUNT = gql(`
    query QuizEventAvailableCount(
        $grade: Int
    ) {
        quizEventAvailableCount(
            grade: $grade
        ) {
            count
            details {
                ID
                name
                usedQuestions
                remainingQuestions
                possibleQuizCount
            }
        }
    }
`);

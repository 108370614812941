import { Chip, Stack } from "@mui/material";
import { Consent } from "../../__generated__/graphql";
import { FormattedMessage } from "react-intl";

interface Props {
  consents?: Consent[];
}

const CONSENTS = {
  CONTACT: "contact",
};

export const StudentConsents = ({ consents }: Props) => {
  const contactConsent =
    consents?.find((consent) => consent.name === CONSENTS.CONTACT)?.status ||
    false;

  return (
    <Stack direction="row" spacing={1}>
      <Chip
        label={<FormattedMessage id="consentContactLabel" />}
        size="small"
        color={contactConsent ? "success" : "error"}
      />
    </Stack>
  );
};

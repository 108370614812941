import { DesignTokens } from "../../style/DesignTokens";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${DesignTokens.spacing.space16};
`;

interface Props {
  children: React.ReactNode;
}

export const Page = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

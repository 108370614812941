import { DesignTokens } from "../../style/DesignTokens";
import { Paper } from "@mui/material";
import { ReactNode } from "react";
import styled from "@emotion/styled";

const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: ${DesignTokens.spacing.space16};
  margin-bottom: ${DesignTokens.spacing.space16};
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: ${DesignTokens.spacing.space16};
`;

interface Props {
  children: ReactNode;
  title?: ReactNode;
}

export const PageSection = ({ title, children }: Props) => {
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {children}
    </Wrapper>
  );
};

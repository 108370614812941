import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { QUESTION_GRADE_OPTIONS } from "../../config/Questions";
import styled from "@emotion/styled";

const Wrapper = styled(FormControl)`
  min-width: 150px;
`;

interface Props {
  grade: string;
  setGrade: (grade: string) => void;
  size?: "small" | "medium" | undefined;
  showAllOption?: boolean;
}

export const GradeSelect = ({
  grade,
  setGrade,
  size = "small",
  showAllOption = true,
}: Props) => {
  const handleGradeChange = (event: SelectChangeEvent<string>) => {
    setGrade(event.target.value);
  };

  return (
    <Wrapper size={size}>
      <InputLabel>
        <FormattedMessage id="gradeLabel" />
      </InputLabel>
      <Select
        value={grade}
        label={<FormattedMessage id="gradeLabel" />}
        onChange={handleGradeChange}
      >
        {showAllOption && (
          <MenuItem value="">
            <FormattedMessage id="selectAllOption" />
          </MenuItem>
        )}
        {QUESTION_GRADE_OPTIONS.map((grade) => {
          const gradeString = grade.toString();
          return (
            <MenuItem key={gradeString} value={gradeString}>
              {gradeString}
            </MenuItem>
          );
        })}
      </Select>
    </Wrapper>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

interface Props {
  title: React.ReactNode;
  description: React.ReactNode;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const AlertDialog = ({
  title,
  description,
  open,
  onClose,
  onConfirm,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="noButton" />
        </Button>
        <Button variant="contained" onClick={onConfirm} autoFocus>
          <FormattedMessage id="yesButton" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

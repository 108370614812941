import { ApolloError, useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { CREATE_STUDENT, UPDATE_STUDENT } from "../../graphql/Students";
import { useEffect, useState } from "react";
import { AuthRole } from "../../config/Enums";
import { DesignTokens } from "../../style/DesignTokens";
import { FormattedMessage } from "react-intl";
import { GradeSelect } from "../GradeSelect/GradeSelect";
import { SchoolSelect } from "../SchoolSelect/SchoolSelect";
import { Student } from "../../__generated__/graphql";
import { isEmailValid } from "../../utils/UserUtils";
import { logError } from "../../utils/Logger";
import { useAppContext } from "../../context/AppContext";
import { useSnackbarContext } from "../../context/SnackbarContext";

const ContentStack = styled(Stack)`
  margin-top: ${DesignTokens.spacing.space8};
  min-width: 360px;
`;

interface Props {
  open: boolean;
  student?: Student;
  onUpdate: () => void;
  onClose: () => void;
}

export const StudentDialog = ({ open, student, onUpdate, onClose }: Props) => {
  const { userRole, teacher } = useAppContext();
  const teacherMode = userRole === AuthRole.TEACHER;

  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [grade, setGrade] = useState("");
  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const { showSnack } = useSnackbarContext();

  const mutationSuccess = () => {
    onUpdate();
    onClose();
  };

  const mutationError = (error: ApolloError) => {
    showSnack(<FormattedMessage id="unknownError" />, "error");
    logError(error.message);
  };

  const [createStudent, createStatus] = useMutation(CREATE_STUDENT, {
    onError: mutationError,
    onCompleted: (data) => {
      if (data.createStudent.student?.id) {
        showSnack(
          <FormattedMessage id="studentsDialogAddMessage" />,
          "success"
        );
        mutationSuccess();
      } else {
        showSnack(<FormattedMessage id="unknownError" />, "error");
      }
    },
  });

  const [updateStudent, updateStatus] = useMutation(UPDATE_STUDENT, {
    onError: mutationError,
    onCompleted: (data) => {
      if (data.updateStudent.student?.id) {
        showSnack(
          <FormattedMessage id="studentsDialogUpdateMessage" />,
          "success"
        );
        mutationSuccess();
      } else {
        showSnack(<FormattedMessage id="unknownError" />, "error");
      }
    },
  });

  useEffect(() => {
    setIsLoading(createStatus.loading || updateStatus.loading);
  }, [createStatus, updateStatus]);

  useEffect(() => {
    if (
      student &&
      student.firstName &&
      student.surname &&
      student.school?.id &&
      student.email &&
      student.grade
    ) {
      setFirstName(student.firstName);
      setSurname(student.surname);
      setSchoolId(student.school.id);
      setEmail(student.email);
      setGrade(student.grade.toString());
    } else {
      setFirstName("");
      setSurname("");
      setSchoolId(teacher?.school?.id || "");
      setEmail("");
      setGrade("");
    }
  }, [student, open, teacher]);

  const onConfirm = () => {
    if (!isEmailValid(email)) {
      showSnack(<FormattedMessage id="invalidEmail" />, "error");
      return;
    }

    if (student && student.id) {
      updateStudent({
        variables: {
          input: {
            id: student.id,
            firstName,
            surname,
            email,
            schoolId,
            grade: parseInt(grade),
          },
        },
      });
    } else {
      createStudent({
        variables: {
          input: {
            firstName,
            surname,
            email,
            schoolId,
            grade: parseInt(grade),
          },
        },
      });
    }
  };

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };

  const handleSurnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSurname(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage
          id={student ? "studentsDialogUpdateTitle" : "studentsDialogAddTitle"}
        />
      </DialogTitle>
      <DialogContent>
        <ContentStack spacing={2}>
          <GradeSelect
            grade={grade}
            setGrade={setGrade}
            showAllOption={false}
          />
          {!teacherMode && (
            <SchoolSelect
              showAllOption={false}
              schoolId={schoolId}
              setSchoolId={setSchoolId}
            />
          )}
          <TextField
            fullWidth
            autoComplete="off"
            size="small"
            inputProps={{ maxLength: 30 }}
            label={<FormattedMessage id="firstNameLabel" />}
            value={firstName}
            onChange={handleFirstNameChange}
          />
          <TextField
            fullWidth
            autoComplete="off"
            size="small"
            inputProps={{ maxLength: 30 }}
            label={<FormattedMessage id="surnameLabel" />}
            value={surname}
            onChange={handleSurnameChange}
          />
          <TextField
            fullWidth
            autoComplete="off"
            size="small"
            inputProps={{ maxLength: 254 }}
            label={<FormattedMessage id="emailLabel" />}
            type="email"
            value={email}
            disabled={!!student}
            onChange={handleEmailChange}
          />
          {!!student && (
            <>
              <Typography variant="caption" color={DesignTokens.colors.orange}>
                <FormattedMessage id="studentEmailChangeWarning0" />
              </Typography>
              <Typography variant="caption" color={DesignTokens.colors.orange}>
                <FormattedMessage id="studentEmailChangeWarning1" />
              </Typography>
            </>
          )}
        </ContentStack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>
          <FormattedMessage id="cancelButton" />
        </Button>
        <Button
          disabled={
            !open ||
            firstName.length === 0 ||
            surname.length === 0 ||
            schoolId.length === 0 ||
            email.length === 0 ||
            grade == null ||
            isLoading
          }
          variant="contained"
          onClick={onConfirm}
          autoFocus
        >
          {isLoading ? (
            <FormattedMessage id="savingMessage" />
          ) : (
            <FormattedMessage
              id={
                student
                  ? "studentsDialogUpdateButton"
                  : "studentsDialogAddButton"
              }
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { DesignTokens } from "../../style/DesignTokens";
import { Typography } from "@mui/material";

interface Props {
  value: number;
  maxValue: number;
}

export const WatchedStatsValue = ({ value, maxValue }: Props) => {
  const ratioValue = value / maxValue;
  if (ratioValue < 0.33) {
    return <Typography color={DesignTokens.colors.red}>{value}</Typography>;
  }

  if (ratioValue < 0.66) {
    return <Typography color={DesignTokens.colors.orange}>{value}</Typography>;
  }

  return <Typography>{value}</Typography>;
};

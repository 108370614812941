import { ApolloError, useMutation } from "@apollo/client";
import Papa, { ParseResult } from "papaparse";
import { logError, logInfo } from "../../utils/Logger";
import { Button } from "@mui/material";
import { CREATE_TEXT_QUESTION } from "../../graphql/Question";
import { ChangeEvent } from "react";
import { CreateQuestionStatus } from "../../__generated__/graphql";
import { FormattedMessage } from "react-intl";

export const CsvImportButton = () => {
  const [createTextQuestion] = useMutation(CREATE_TEXT_QUESTION, {
    onError: (error: ApolloError) => {
      logError(error.message);
    },
    onCompleted: (data) => {
      if (data.createTextQuestion?.status === CreateQuestionStatus.Success) {
        logInfo("Question created");
      } else {
        logError("Unknown create question error");
      }
    },
  });

  // Internal tool, only available on localhost
  if (window.location.hostname !== "localhost") {
    return null;
  }

  const tryAddQuestion = (questionData: Record<string, unknown>) => {
    const {
      difficulty,
      category,
      question,
      correctAnswer,
      wrongAnswer0,
      wrongAnswer1,
      wrongAnswer2,
    } = questionData;

    if (
      difficulty === undefined ||
      category === undefined ||
      question === undefined ||
      correctAnswer === undefined ||
      wrongAnswer0 === undefined ||
      wrongAnswer1 === undefined ||
      wrongAnswer2 === undefined
    ) {
      console.warn("Invalid question data", questionData);
      return;
    }

    const wrongAnswers = [
      String(wrongAnswer0),
      String(wrongAnswer1),
      String(wrongAnswer2),
    ].filter((f) => f.length > 0);
    const answers = [
      {
        text: String(correctAnswer),
        correct: true,
      },
      ...wrongAnswers.map((answer) => ({
        text: answer,
        correct: false,
      })),
    ];
    const randomAnswers = answers.sort(() => Math.random() - 0.5);

    const input = {
      draft: false,
      grades: [1, 2, 3, 4],
      difficulty: String(difficulty),
      category: String(category),
      text: String(question),
      answers: randomAnswers,
    };

    createTextQuestion({
      variables: { input },
    });
  };

  const handleCsvImport = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results: ParseResult<Record<string, unknown>>) => {
        results.data.forEach((question) => {
          tryAddQuestion(question);
        });
      },
    });
  };

  return (
    <Button component="label">
      <FormattedMessage id="questionsImportCsvButton" />
      <input hidden accept=".csv" type="file" onChange={handleCsvImport} />
    </Button>
  );
};

import { Avatar, Link, Stack, TableCell } from "@mui/material";
import { Student } from "../../__generated__/graphql";
import Typography from "@mui/material/Typography";
import { concatUserName } from "../../utils/UserUtils";

interface Props {
  student: Student;
}

export const StatsStudentInfo = ({ student }: Props) => {
  return (
    <TableCell>
      <Stack direction="row" spacing={2} alignItems="center">
        {student.avatar && <Avatar src={student.avatar} />}
        <div>
          <Typography variant="caption">{student.nickname}</Typography>
          <div>{concatUserName(student)}</div>
          <Link href={`mailto:${student.email}`}>
            <Typography variant="caption">{student.email}</Typography>
          </Link>
        </div>
      </Stack>
    </TableCell>
  );
};

import {
  ImageEditorQuestion,
  Teacher,
  TextEditorQuestion,
} from "../__generated__/graphql";
import React, { useContext } from "react";
import { AuthRole } from "../config/Enums";

interface AppContextInterface {
  userRole?: AuthRole;
  userEmail?: string;
  teacher?: Teacher;
  editQuestion?: TextEditorQuestion | ImageEditorQuestion;
  setEditQuestion: (
    question?: TextEditorQuestion | ImageEditorQuestion
  ) => void;
}

export const AppContext = React.createContext<AppContextInterface>({
  userRole: undefined,
  userEmail: undefined,
  teacher: undefined,
  editQuestion: undefined,
  setEditQuestion: () => {},
});

export const useAppContext = () => {
  return useContext(AppContext);
};

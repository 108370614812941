import { gql } from "../__generated__/gql";

export const GET_USER = gql(`
    query GetUser {
        me {
            id
            email
            ... on Teacher {
                school {
                    id
                    name
                }
            }
        }
    }
`);

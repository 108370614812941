import { gql } from "../__generated__/gql";

export const GET_TEACHERS = gql(`
  query GetTeachers(
    $first: Int,
    $after: Int,
    $schoolId: ID
  ) {
    teachers (
      first: $first,
      after: $after,
      schoolId: $schoolId
    ) {
      edges {
        node {
          id
          firstName
          surname
          email
          school {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const CREATE_TEACHER = gql(`
  mutation CreateTeacher($input: CreateTeacherInput!) {
    createTeacher(input: $input) {
      teacher {
        id
      }
    }
  }
`);

export const UPDATE_TEACHER = gql(`
  mutation UpdateTeacher($input: UpdateTeacherInput!) {
    updateTeacher(input: $input) {
      teacher {
        id
      }
    }
  }
`);

export const DELETE_TEACHER = gql(`
  mutation DeleteTeacher($id: ID!) {
    deleteTeacher(id: $id) {
      success
    }
  }
`);

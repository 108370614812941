import {
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { CategorySelect } from "../CategorySelect/CategorySelect";
import { DesignTokens } from "../../style/DesignTokens";
import { DifficultySelect } from "../DifficultySelect/DifficultySelect";
import { FormattedMessage } from "react-intl";
import { PageSection } from "../PageSection/PageSection";
import { QUESTION_GRADE_OPTIONS } from "../../config/Questions";
import { QuestionTypeChip } from "../QuestionTypeChip/QuestionTypeChip";
import styled from "@emotion/styled";

const GradesTitle = styled.span`
  margin-right: ${DesignTokens.spacing.space16};
  font-weight: bold;
`;

interface Props {
  type: "text" | "image";
  grades: number[];
  setGrades: (grade: number[]) => void;
  difficultyId: string;
  setDifficultyId: (value: string) => void;
  categoryId: string;
  setCategoryId: (value: string) => void;
  text: string;
  setText: (value: string) => void;
}

export const QuestionEditorProperties = ({
  type,
  grades,
  setGrades,
  difficultyId,
  setDifficultyId,
  categoryId,
  setCategoryId,
  text,
  setText,
}: Props) => {
  const handleGradeChange = (
    event: React.MouseEvent<HTMLElement>,
    newGrades: number[]
  ) => {
    setGrades(newGrades);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  return (
    <PageSection>
      <Stack alignItems="flex-start" spacing={2}>
        <QuestionTypeChip type={type} />
        <div>
          <GradesTitle>
            <FormattedMessage id="questionEditorGradesLabel" />
          </GradesTitle>
          <ToggleButtonGroup value={grades} onChange={handleGradeChange}>
            {QUESTION_GRADE_OPTIONS.map((grade) => (
              <ToggleButton key={grade} value={grade}>
                {grade}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
        <DifficultySelect
          difficultyId={difficultyId}
          setDifficultyId={setDifficultyId}
        />
        <CategorySelect categoryId={categoryId} setCategoryId={setCategoryId} />
        <TextField
          fullWidth
          multiline
          minRows={4}
          label={<FormattedMessage id="questionEditorTextLabel" />}
          value={text}
          onChange={handleTextChange}
        />
      </Stack>
    </PageSection>
  );
};

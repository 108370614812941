import { gql } from "../__generated__/gql";

export const GET_QUESTION_CATEGORIES = gql(`
  query GetQuestionCategories {
    questionCategories {
      id
      name
    }
  }
`);

export const CREATE_QUESTION_CATEGORY = gql(`
  mutation CreateQuestionCategory($input: CreateQuestionCategoryInput!) {
    createQuestionCategory(input: $input) {
      category {
        id
      }
    }
  }
`);

export const UPDATE_QUESTION_CATEGORY = gql(`
  mutation UpdateQuestionCategory($input: UpdateQuestionCategoryInput!) {
    updateQuestionCategory(input: $input) {
      status
    }
  }
`);

export const DELETE_QUESTION_CATEGORY = gql(`
  mutation DeleteQuestionCategory($id: ID!) {
    deleteQuestionCategory(id: $id) {
      status
    }
  }
`);
import { FormattedMessage, MessageDescriptor } from "react-intl";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { AuthRole } from "../../config/Enums";
import CategoryIcon from "@mui/icons-material/Category";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { DesignTokens } from "../../style/DesignTokens";
import GroupIcon from "@mui/icons-material/Group";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavigationSection } from "./NavigationSection";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import QuizIcon from "@mui/icons-material/Quiz";
import { ROUTE } from "../../config/Routes";
import SchoolIcon from "@mui/icons-material/School";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import StarIcon from "@mui/icons-material/Star";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import styled from "@emotion/styled";
import { useAppContext } from "../../context/AppContext";
import { useMatch } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

export interface NavigationItem {
  label: MessageDescriptor;
  route: {
    PATH: string;
    ROLES: AuthRole[];
  };
  icon: JSX.Element;
}

const NAVIGATION_MAIN_ITEMS: NavigationItem[] = [
  {
    label: { id: "navigationDashboard" },
    route: ROUTE.DASHBOARD,
    icon: <DashboardIcon />,
  },
  {
    label: { id: "navigationSchools" },
    route: ROUTE.SCHOOLS,
    icon: <SchoolIcon />,
  },
  {
    label: { id: "navigationTeachers" },
    route: ROUTE.TEACHERS,
    icon: <SupervisedUserCircleIcon />,
  },
  {
    label: { id: "navigationStudents" },
    route: ROUTE.STUDENTS,
    icon: <GroupIcon />,
  },
];

const NAVIGATION_QUESTIONS_ITEMS: NavigationItem[] = [
  {
    label: { id: "navigationQuestions" },
    route: ROUTE.QUESTIONS,
    icon: <QuestionMarkIcon />,
  },
  {
    label: { id: "navigationCategories" },
    route: ROUTE.QUESTION_CATEGORIES,
    icon: <CategoryIcon />,
  },
  {
    label: { id: "navigationDifficulty" },
    route: ROUTE.QUESTION_DIFFICULTY,
    icon: <StarIcon />,
  },
  {
    label: { id: "navigationStatsTests" },
    route: ROUTE.TESTS_STATS,
    icon: <QuizIcon />,
  },
];

const NAVIGATION_STATS_ITEMS: NavigationItem[] = [
  {
    label: { id: "navigationStatsStudents" },
    route: ROUTE.STUDENTS_STATS,
    icon: <LeaderboardIcon />,
  },
  {
    label: { id: "navigationStatsShools" },
    route: ROUTE.SCHOOLS_STATS,
    icon: <SsidChartIcon />,
  },
  {
    label: { id: "navigationStatsQuestion" },
    route: ROUTE.QUESTION_STATS,
    icon: <ShowChartIcon />,
  },
];

const MenuWrapper = styled.div`
  margin-top: ${DesignTokens.spacing.space16};
`;

export const Navigation = () => {
  const { instance, accounts } = useMsal();
  const match = useMatch(ROUTE.ROOT.PATH);
  const { userRole, userEmail } = useAppContext();

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: ROUTE.ROOT.PATH,
      mainWindowRedirectUri: ROUTE.ROOT.PATH,
    });
  };

  if (match || accounts.length === 0) {
    return null;
  }

  return (
    <MenuWrapper>
      <Paper>
        <MenuList>
          <NavigationSection showDivider items={NAVIGATION_MAIN_ITEMS} />
          {userRole === AuthRole.ADMIN && (
            <NavigationSection
              title={{ id: "navigationSectionQuestions" }}
              showDivider
              items={NAVIGATION_QUESTIONS_ITEMS}
            />
          )}
          <NavigationSection
            title={{ id: "navigationSectionStats" }}
            showDivider
            items={NAVIGATION_STATS_ITEMS}
          />
          <MenuItem disabled>
            <Stack>
              <Typography variant="subtitle1">
                <FormattedMessage
                  id={
                    userRole === AuthRole.ADMIN ? "adminLabel" : "teacherLabel"
                  }
                />
              </Typography>
              <Typography variant="caption">
                {userEmail?.split("@")[0]}
              </Typography>
            </Stack>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="navigationLogout" />
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    </MenuWrapper>
  );
};

import {
  Button,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AccuracyValue } from "../../components/AccuracyValue/AccuracyValue";
import { CategorySelect } from "../../components/CategorySelect/CategorySelect";
import { DifficultySelect } from "../../components/DifficultySelect/DifficultySelect";
import { FormattedMessage } from "react-intl";
import { GET_QUESTIONS_STATS } from "../../graphql/Question";
import { GradeSelect } from "../../components/GradeSelect/GradeSelect";
import { Page } from "../../components/Page/Page";
import { PageSection } from "../../components/PageSection/PageSection";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";
import { ROUTE } from "../../config/Routes";
import { SchoolSelect } from "../../components/SchoolSelect/SchoolSelect";
import { WatchedStatsValue } from "../../components/WatchedStatsValue/WatchedStatsValue";
import { logError } from "../../utils/Logger";
import { msToTimeString } from "../../utils/TimeUtils";
import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";
import { useSnackbarContext } from "../../context/SnackbarContext";

const SearchTextField = styled(TextField)`
  flex: 1;
`;

export const QuestionStats = () => {
  const [categoryId, setCategoryId] = useState("");
  const [difficultyId, setDifficultyId] = useState("");
  const [grade, setGrade] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const { showSnack } = useSnackbarContext();

  const questionsStatsQuery = useQuery(GET_QUESTIONS_STATS, {
    onError: (error) => {
      showSnack(<FormattedMessage id="unknownError" />, "error");
      logError(error.message);
    },
  });
  const questionsStats = (
    questionsStatsQuery.data?.questionsStats || []
  ).flatMap((f) => (f ? [f] : []));

  useEffect(() => {
    questionsStatsQuery.refetch({
      category: categoryId.length > 0 ? categoryId : undefined,
      difficulty: difficultyId.length > 0 ? difficultyId : undefined,
      school: schoolId.length > 0 ? schoolId : undefined,
      grade: grade.length > 0 ? parseInt(grade) : undefined,
      search: debouncedSearchText.length > 2 ? debouncedSearchText : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, difficultyId, schoolId, grade, debouncedSearchText]);

  useEffect(() => {
    const updateDebounceTerm = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);

    return () => clearTimeout(updateDebounceTerm);
  }, [searchText]);

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length > 100) return;
    setSearchText(event.target.value);
  };

  const handleFilterReset = () => {
    setCategoryId("");
    setDifficultyId("");
    setSchoolId("");
    setGrade("");
    setSearchText("");
  };

  return (
    <ProtectedRoute roles={ROUTE.QUESTION_STATS.ROLES}>
      <Page>
        <PageSection title={<FormattedMessage id="filterLabel" />}>
          <Stack direction="row" spacing={1}>
            <CategorySelect
              categoryId={categoryId}
              setCategoryId={setCategoryId}
            />
            <DifficultySelect
              difficultyId={difficultyId}
              setDifficultyId={setDifficultyId}
            />
            <GradeSelect grade={grade} setGrade={setGrade} />
            <SchoolSelect schoolId={schoolId} setSchoolId={setSchoolId} />
            <SearchTextField
              label="Hledat text"
              size="small"
              value={searchText}
              onChange={handleSearchTextChange}
            />
            <Button onClick={handleFilterReset}>
              <FormattedMessage id="resetFiltersButton" />
            </Button>
          </Stack>
        </PageSection>
        <PageSection>
          {questionsStatsQuery.loading && <LinearProgress />}
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="questionsStatsQuestionLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="questionsStatsMaxPointsLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="questionsStatsAveragePointsLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="questionsStatsTimeLimitLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="questionsStatsAverageTimeLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="questionsStatsSuccessRateLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="questionsStatsTotalAnswersLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="questionsStatsCorrectAnswersLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="questionsStatsWrongAnswersLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="questionsStatsMaxPointsAnswersLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="questionsStatsTimeoutsLabel" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {questionsStats.map((data) => (
                  <TableRow key={data.question?.id}>
                    <TableCell>{data.question?.text}</TableCell>
                    <TableCell align="right">{data.maxPoints}</TableCell>
                    <TableCell align="right">
                      {data.averagePoints && data.maxPoints && (
                        <WatchedStatsValue
                          value={data.averagePoints}
                          maxValue={data.maxPoints}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {data.readTimeLimit &&
                        data.timeLimit &&
                        `${msToTimeString(
                          data.readTimeLimit
                        )} / ${msToTimeString(
                          data.readTimeLimit + data.timeLimit
                        )}`}
                    </TableCell>
                    <TableCell align="right">
                      {msToTimeString(data.averageTime)}
                    </TableCell>
                    <TableCell align="right">
                      {data.succesRate && (
                        <AccuracyValue value={data.succesRate} />
                      )}
                    </TableCell>
                    <TableCell align="right">{data.totalAnswers}</TableCell>
                    <TableCell align="right">{data.correctAnswers}</TableCell>
                    <TableCell align="right">{data.wrongAnswers}</TableCell>
                    <TableCell align="right">{data.maxPointsAnswers}</TableCell>
                    <TableCell align="right">{data.timeouts}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PageSection>
      </Page>
    </ProtectedRoute>
  );
};

import {
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DELETE_SCHOOL, GET_SCHOOLS } from "../../graphql/Schools";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { AlertDialog } from "../../components/AlertDialog/AlertDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { FormattedMessage } from "react-intl";
import { Page } from "../../components/Page/Page";
import { PageSection } from "../../components/PageSection/PageSection";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";
import { ROUTE } from "../../config/Routes";
import { School } from "../../__generated__/graphql";
import { SchoolDialog } from "../../components/SchoolDialog/SchoolDialog";
import { logError } from "../../utils/Logger";
import { useSnackbarContext } from "../../context/SnackbarContext";

export const Schools = () => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState<School | undefined>(
    undefined
  );
  const [deleteId, setDeleteId] = useState<string>();
  const { showSnack } = useSnackbarContext();
  const schoolsLeaderboard = useQuery(GET_SCHOOLS);
  const leaderboardData = schoolsLeaderboard.data?.schools || [];

  useEffect(() => {
    schoolsLeaderboard.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [deleteSchool, deleteStatus] = useMutation(DELETE_SCHOOL, {
    onError: (error) => {
      showSnack(<FormattedMessage id="unknownError" />, "error");
      logError(error.message);
    },
    onCompleted: (data) => {
      if (data.deleteSchool.success) {
        showSnack(<FormattedMessage id="schoolsDeletedMessage" />, "success");
        schoolsLeaderboard.refetch();
      } else {
        showSnack(<FormattedMessage id="unknownError" />, "error");
      }
    },
  });

  const handleAdd = () => {
    setSelectedSchool(undefined);
    setShowEditDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(undefined);
  };

  const handleConfirmDelete = () => {
    if (deleteId) {
      deleteSchool({ variables: { id: deleteId } });
    }
    setDeleteId(undefined);
  };

  const handleCloseEditDialog = () => {
    setShowEditDialog(false);
  };

  return (
    <>
      <ProtectedRoute roles={ROUTE.SCHOOLS.ROLES}>
        <Page>
          <PageSection>
            <Stack direction="row">
              <Button onClick={handleAdd} variant="contained">
                <FormattedMessage id="schoolsAddButton" />
              </Button>
            </Stack>
          </PageSection>
          <PageSection>
            {(schoolsLeaderboard.loading || deleteStatus.loading) && (
              <LinearProgress />
            )}
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="nameLabel" />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedMessage id="actionsLabel" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaderboardData.map((school) => {
                    return (
                      <TableRow key={school?.id}>
                        <TableCell>{school?.name}</TableCell>
                        <TableCell width={120} align="right">
                          <IconButton
                            color="success"
                            onClick={() => {
                              if (school) {
                                setSelectedSchool(school);
                                setShowEditDialog(true);
                              }
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => {
                              if (school?.id) {
                                setDeleteId(school.id);
                              }
                            }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </PageSection>
        </Page>
      </ProtectedRoute>
      <AlertDialog
        title={<FormattedMessage id="schoolsDeleteDialogTitle" />}
        description={<FormattedMessage id="schoolsDeleteDialogDescription" />}
        open={deleteId !== undefined}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
      />
      <SchoolDialog
        open={showEditDialog}
        school={selectedSchool}
        onClose={handleCloseEditDialog}
        onUpdate={schoolsLeaderboard.refetch}
      />
    </>
  );
};

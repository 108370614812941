import { LogLevel } from "@azure/msal-browser";
import { ROUTE } from "./Routes";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID || "",
    authority: process.env.REACT_APP_AUTH_AUTHORITY || "",
    knownAuthorities: [process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES || ""],
    redirectUri: ROUTE.ROOT.PATH,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [
    "openid",
    `${process.env.REACT_APP_AUTH_API_SCOPE}profile.write`,
    `${process.env.REACT_APP_AUTH_API_SCOPE}profile.read`,
  ],
};

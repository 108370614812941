import {
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DELETE_QUESTION_DIFFICULTY,
  GET_QUESTION_DIFFUCULTY_LEVELS,
} from "../../graphql/QuestionDifficulty";
import {
  DeleteQuestionDifficultyStatus,
  QuestionDifficulty as QuestionDifficultyType,
} from "../../__generated__/graphql";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { AlertDialog } from "../../components/AlertDialog/AlertDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { FormattedMessage } from "react-intl";
import { Page } from "../../components/Page/Page";
import { PageSection } from "../../components/PageSection/PageSection";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";
import { QuestionDifficultyDialog } from "../../components/QuestionDifficultyDialog/QuestionDifficultyDialog";
import { ROUTE } from "../../config/Routes";
import { logError } from "../../utils/Logger";
import { useSnackbarContext } from "../../context/SnackbarContext";

export const QuestionDifficulty = () => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedDifficulty, setSelectedDifficulty] = useState<
    QuestionDifficultyType | undefined
  >(undefined);
  const [deleteDifficultyId, setDeleteDifficultyId] = useState<string>();
  const { showSnack } = useSnackbarContext();
  const { data, loading, refetch } = useQuery(GET_QUESTION_DIFFUCULTY_LEVELS);
  const questionDifficultyLevels = data?.questionDifficultyLevels || [];

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [deleteQuestionDifficulty, deleteStatus] = useMutation(
    DELETE_QUESTION_DIFFICULTY,
    {
      onError: (error) => {
        showSnack(<FormattedMessage id="unknownError" />, "error");
        logError(error.message);
      },
      onCompleted: (data) => {
        if (
          data.deleteQuestionDifficulty?.status ===
          DeleteQuestionDifficultyStatus.Success
        ) {
          showSnack(
            <FormattedMessage id="questionDifficultyDeletedMessage" />,
            "success"
          );
          refetch();
        } else {
          showSnack(<FormattedMessage id="unknownError" />, "error");
        }
      },
    }
  );

  const handleAdd = () => {
    setSelectedDifficulty(undefined);
    setShowEditDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDifficultyId(undefined);
  };

  const handleConfirmDelete = () => {
    if (deleteDifficultyId) {
      deleteQuestionDifficulty({ variables: { id: deleteDifficultyId } });
    }
    setDeleteDifficultyId(undefined);
  };

  const handleCloseEditDialog = () => {
    setShowEditDialog(false);
  };

  return (
    <>
      <ProtectedRoute roles={ROUTE.QUESTION_DIFFICULTY.ROLES}>
        <Page>
          <PageSection>
            <Stack direction="row">
              <Button onClick={handleAdd} variant="contained">
                <FormattedMessage id="questionDifficultyAddButton" />
              </Button>
            </Stack>
          </PageSection>
          <PageSection>
            {(loading || deleteStatus.loading) && <LinearProgress />}
            {!loading && questionDifficultyLevels.length === 0 ? (
              <Typography variant="h6">
                <FormattedMessage id="emptyListMessage" />
              </Typography>
            ) : (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="nameLabel" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="pointsLabel" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="questionDifficultyCountInQuizLabel" />
                      </TableCell>
                      <TableCell align="right">
                        <FormattedMessage id="actionsLabel" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {questionDifficultyLevels.map((difficulty) => {
                      return (
                        <TableRow key={difficulty.id}>
                          <TableCell>{difficulty.name}</TableCell>
                          <TableCell>{difficulty.points}</TableCell>
                          <TableCell>{difficulty.countInQuiz}</TableCell>
                          <TableCell width={120} align="right">
                            <IconButton
                              color="success"
                              onClick={() => {
                                setSelectedDifficulty(difficulty);
                                setShowEditDialog(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => {
                                if (difficulty.id) {
                                  setDeleteDifficultyId(difficulty.id);
                                }
                              }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </PageSection>
        </Page>
      </ProtectedRoute>
      <AlertDialog
        title={<FormattedMessage id="questionDifficultyDeleteDialogTitle" />}
        description={
          <FormattedMessage id="questionDifficultyDeleteDialogDescription" />
        }
        open={deleteDifficultyId !== undefined}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
      />
      <QuestionDifficultyDialog
        open={showEditDialog}
        difficulty={selectedDifficulty}
        onClose={handleCloseEditDialog}
        onUpdate={refetch}
      />
    </>
  );
};

import {
  Button,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  INPUT_DATE_FORMAT,
  getIsoDate,
  validateDateChange,
} from "../../utils/TimeUtils";
import { useEffect, useState } from "react";
import { AccuracyValue } from "../../components/AccuracyValue/AccuracyValue";
import { AuthRole } from "../../config/Enums";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { FormattedMessage } from "react-intl";
import { GET_STUDENTS_LEADERBOARD } from "../../graphql/Students";
import { GradeSelect } from "../../components/GradeSelect/GradeSelect";
import { Page } from "../../components/Page/Page";
import { PageSection } from "../../components/PageSection/PageSection";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";
import { ROUTE } from "../../config/Routes";
import { SchoolSelect } from "../../components/SchoolSelect/SchoolSelect";
import { StatsStudentInfo } from "../../components/StatsStudentInfo/StatsStudentInfo";
import { logError } from "../../utils/Logger";
import { subYears } from "date-fns";
import { useAppContext } from "../../context/AppContext";
import { useQuery } from "@apollo/client";
import { useSnackbarContext } from "../../context/SnackbarContext";

const PAGE_SIZE = 10;

export const StudentsStats = () => {
  const { userRole } = useAppContext();
  const teacherMode = userRole === AuthRole.TEACHER;

  const [startDate, setStartDate] = useState<Date | null>(
    subYears(new Date(), 1)
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [grade, setGrade] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const { showSnack } = useSnackbarContext();

  const studentsQuery = useQuery(GET_STUDENTS_LEADERBOARD, {
    variables: { first: PAGE_SIZE },
    onError(error) {
      showSnack(error.message, "error");
      logError(error.message);
    },
  });

  const studentsLeaderboardData = (
    studentsQuery.data?.studentsAdminLeaderboard?.edges?.map(
      (edge) => edge?.node
    ) || []
  ).flatMap((f) => (f ? [f] : []));
  const pageInfo = studentsQuery.data?.studentsAdminLeaderboard?.pageInfo;

  useEffect(() => {
    studentsQuery.refetch({
      school: schoolId.length > 0 ? schoolId : undefined,
      grade: grade.length > 0 ? parseInt(grade) : undefined,
      startDate: startDate ? getIsoDate(startDate) : undefined,
      endDate: endDate ? getIsoDate(endDate) : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId, grade, startDate, endDate]);

  const loadMore = () => {
    studentsQuery.fetchMore({
      variables: { after: pageInfo?.endCursor },
    });
  };

  const handleStartDateChange = (newValue: string | null) => {
    setStartDate(validateDateChange(newValue));
  };

  const handleEndDateChange = (newValue: string | null) => {
    setEndDate(validateDateChange(newValue));
  };

  const handleFilterReset = () => {
    setSchoolId("");
    setGrade("");
  };

  return (
    <ProtectedRoute roles={ROUTE.STUDENTS_STATS.ROLES}>
      <Page>
        <PageSection title={<FormattedMessage id="filterLabel" />}>
          <Stack direction="row" spacing={1}>
            <DesktopDatePicker
              label={<FormattedMessage id="startLabel" />}
              inputFormat={INPUT_DATE_FORMAT}
              value={startDate}
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
              label={<FormattedMessage id="endLabel" />}
              inputFormat={INPUT_DATE_FORMAT}
              value={endDate}
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
            {!teacherMode && (
              <SchoolSelect
                size="medium"
                schoolId={schoolId}
                setSchoolId={setSchoolId}
              />
            )}
            <GradeSelect size="medium" grade={grade} setGrade={setGrade} />
            <Button onClick={handleFilterReset}>
              <FormattedMessage id="resetFiltersButton" />
            </Button>
          </Stack>
        </PageSection>
        <PageSection>
          {studentsQuery.loading && <LinearProgress />}
          <Stack spacing={2}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="globalRankLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="schoolRankLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="pointsLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="studentLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="schoolLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="gradeLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="accuracyLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="completedQuizzesLabel" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentsLeaderboardData.map((data) => {
                    return (
                      <TableRow key={data.student?.id}>
                        <TableCell>{data.stats?.globalRank}</TableCell>
                        <TableCell>{data.stats?.schoolRank}</TableCell>
                        <TableCell>{data.stats?.points}</TableCell>
                        {data.student && (
                          <StatsStudentInfo student={data.student} />
                        )}
                        <TableCell>{data.student?.school?.name}</TableCell>
                        <TableCell>{data.student?.grade}</TableCell>
                        <TableCell>
                          {data.stats?.accuracy && (
                            <AccuracyValue value={data.stats.accuracy} />
                          )}
                        </TableCell>
                        <TableCell>{data.stats?.completedQuizCount}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {pageInfo?.hasNextPage && (
              <Button disabled={studentsQuery.loading} onClick={loadMore}>
                <FormattedMessage id="loadMoreButton" />
              </Button>
            )}
          </Stack>
        </PageSection>
      </Page>
    </ProtectedRoute>
  );
};

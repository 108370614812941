import {
  INPUT_DATE_FORMAT,
  getIsoDate,
  validateDateChange,
} from "../../utils/TimeUtils";
import {
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { AccuracyValue } from "../../components/AccuracyValue/AccuracyValue";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { FormattedMessage } from "react-intl";
import { GET_SCHOOLS_LEADERBOARD } from "../../graphql/Schools";
import { Page } from "../../components/Page/Page";
import { PageSection } from "../../components/PageSection/PageSection";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";
import { ROUTE } from "../../config/Routes";
import { logError } from "../../utils/Logger";
import { subYears } from "date-fns";
import { useQuery } from "@apollo/client";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useState } from "react";

export const SchoolsStats = () => {
  const { showSnack } = useSnackbarContext();
  const [startDate, setStartDate] = useState<Date | null>(
    subYears(new Date(), 1)
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const schoolsLeaderboard = useQuery(GET_SCHOOLS_LEADERBOARD, {
    variables: {
      startDate: startDate ? getIsoDate(startDate) : undefined,
      endDate: endDate ? getIsoDate(endDate) : undefined,
    },
    onError(error) {
      showSnack(error.message, "error");
      logError(error.message);
    },
  });
  const leaderboardData =
    schoolsLeaderboard.data?.schoolsAdminLeaderboard || [];

  const handleStartDateChange = (newValue: string | null) => {
    setStartDate(validateDateChange(newValue));
  };

  const handleEndDateChange = (newValue: string | null) => {
    setEndDate(validateDateChange(newValue));
  };

  return (
    <ProtectedRoute roles={ROUTE.SCHOOLS_STATS.ROLES}>
      <Page>
        <PageSection title={<FormattedMessage id="filterLabel" />}>
          <Stack direction="row" spacing={1}>
            <DesktopDatePicker
              label={<FormattedMessage id="startLabel" />}
              inputFormat={INPUT_DATE_FORMAT}
              value={startDate}
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
              label={<FormattedMessage id="endLabel" />}
              inputFormat={INPUT_DATE_FORMAT}
              value={endDate}
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </PageSection>
        <PageSection>
          {schoolsLeaderboard.loading && <LinearProgress />}
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="rankLabel" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="nameLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="pointsLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="averagePointsLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="averageAccuracyLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="studentsLabel" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="activeStudentsLabel" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaderboardData.map((school) => {
                  return (
                    <TableRow key={school.school?.id}>
                      <TableCell>{school.rank}</TableCell>
                      <TableCell>{school.school?.name}</TableCell>
                      <TableCell align="right">{school.points}</TableCell>
                      <TableCell align="right">
                        {school.averagePoints}
                      </TableCell>
                      <TableCell align="right">
                        {school.averageAccuracy && (
                          <AccuracyValue value={school.averageAccuracy} />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {school.studentsCount}
                      </TableCell>
                      <TableCell align="right">
                        {school.activeStudentsCount}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </PageSection>
      </Page>
    </ProtectedRoute>
  );
};

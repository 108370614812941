import { gql } from "../__generated__/gql";

export const GET_QUESTIONS = gql(`
  query GetQuestions( 
    $category: ID,
    $difficulty: ID,
    $grade: Int,
    $state: EditorQuestionState
    $search: String
  ) {
    editorQuestions(
      category: $category,
      difficulty: $difficulty,
      grade: $grade,
      state: $state,
      search: $search,
    ) {
      __typename
      id
      state
      text
      grades
      difficulty {
        id
        name
      }
      category {
        id
        name
      }
      answers {
        # TODO: Get ID once added to schema (CPD20-122)
        text
        correct
      }
      ... on ImageEditorQuestion {
        image
      }
    }
  }
`);

export const GET_QUESTIONS_STATS = gql(`
  query QuestionsStats(
    $category: ID,
    $difficulty: ID,
    $school: ID,
    $grade: Int,
    $search: String
  ) {
    questionsStats(
      category: $category,
      difficulty: $difficulty,
      school: $school,
      grade: $grade,
      search: $search,
    ) {
      question {
        id
        text
      }
      maxPoints
      maxPoints
      averagePoints
      readTimeLimit
      timeLimit
      averageTime
      succesRate
      totalAnswers
      correctAnswers
      wrongAnswers
      maxPointsAnswers
      timeouts
    }
  }
`);

export const CREATE_TEXT_QUESTION = gql(`
  mutation CreateTextQuestion($input: CreateTextQuestionInput!) {
    createTextQuestion(input: $input) {
      status
    }
  }
`);

export const CREATE_IMAGE_QUESTION = gql(`
  mutation CreateImageQuestion($input: CreateImageQuestionInput!) {
    createImageQuestion(input: $input) {
      status
    }
  }
`);

export const UPDATE_TEXT_QUESTION = gql(`
  mutation UpdateTextQuestion($input: UpdateTextQuestionInput!) {
    updateTextQuestion(input: $input) {
      status
    }
  }
`);

export const UPDATE_IMAGE_QUESTION = gql(`
  mutation UpdateImageQuestion($input: UpdateImageQuestionInput!) {
    updateImageQuestion(input: $input) {
      status
    }
  }
`);

export const DELETE_QUESTION = gql(`
  mutation DeleteQuestion($id: ID!) {
    deleteQuestion(id: $id) {
      status
    }
  }
`);

export const INIT_QUESTION_IMAGE_UPLOAD = gql(`
  mutation InitQuestionImageUpload {
    initQuestionImageUpload {
      uploadUrl
    }
  }
`);

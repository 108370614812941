import { ApolloError, useMutation } from "@apollo/client";
import { IconButton, Stack, Typography } from "@mui/material";
import { AlertDialog } from "../AlertDialog/AlertDialog";
import ClearIcon from "@mui/icons-material/Clear";
import { FormattedMessage } from "react-intl";
import { Student } from "../../__generated__/graphql";
import { UPDATE_STUDENT } from "../../graphql/Students";
import { logError } from "../../utils/Logger";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useState } from "react";

interface Props {
  data: Student;
  onUpdate: () => void;
}

export const StudentNicknameManagement = ({ data, onUpdate }: Props) => {
  const [deleteNicknameId, setDeleteNicknameId] = useState<string>();
  const { id, nickname } = data;
  const { showSnack } = useSnackbarContext();

  const mutationSuccess = () => {
    onUpdate();
  };

  const mutationError = (error: ApolloError) => {
    showSnack(<FormattedMessage id="unknownError" />, "error");
    logError(error.message);
  };

  const [updateStudent, updateStatus] = useMutation(UPDATE_STUDENT, {
    onError: mutationError,
    onCompleted: (data) => {
      if (data.updateStudent.student?.id) {
        showSnack(
          <FormattedMessage id="studentsDeletedNicknameMessage" />,
          "success"
        );
        mutationSuccess();
      } else {
        showSnack(<FormattedMessage id="unknownError" />, "error");
      }
    },
  });

  const handleCloseDeleteNicknameDialog = () => {
    setDeleteNicknameId(undefined);
  };

  const handleConfirmDeleteNickname = () => {
    if (deleteNicknameId) {
      updateStudent({
        variables: {
          input: {
            id: deleteNicknameId,
            nickname: null,
          },
        },
      });
    }
    setDeleteNicknameId(undefined);
  };

  if (!id || !nickname) {
    return null;
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography>{nickname}</Typography>
        <IconButton
          color="error"
          disabled={updateStatus.loading}
          onClick={() => {
            setDeleteNicknameId(id);
          }}
        >
          <ClearIcon />
        </IconButton>
      </Stack>
      <AlertDialog
        title={<FormattedMessage id="studentsDeleteNicknameDialogTitle" />}
        description={
          <FormattedMessage id="studentsDeleteNicknameDialogDescription" />
        }
        open={deleteNicknameId !== undefined}
        onClose={handleCloseDeleteNicknameDialog}
        onConfirm={handleConfirmDeleteNickname}
      />
    </>
  );
};

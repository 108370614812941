import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { NavigationItem } from "./Navigation";
import { useAppContext } from "../../context/AppContext";

interface Props {
  title?: MessageDescriptor;
  showDivider?: boolean;
  items: NavigationItem[];
}

export const NavigationSection = ({ title, showDivider, items }: Props) => {
  const { userRole } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {title && (
        <MenuItem disabled>
          <FormattedMessage {...title} />
        </MenuItem>
      )}
      {items.map((item) => {
        if (userRole === undefined || !item.route.ROLES.includes(userRole)) {
          return null;
        }
        return (
          <MenuItem
            key={item.route.PATH}
            selected={location.pathname === item.route.PATH}
            onClick={() => {
              navigate(item.route.PATH);
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>
              <FormattedMessage {...item.label} />
            </ListItemText>
          </MenuItem>
        );
      })}
      {showDivider && <Divider />}
    </>
  );
};

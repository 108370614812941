import { DesignTokens } from "../../style/DesignTokens";
import { Typography } from "@mui/material";

interface Props {
  value: number;
}

export const AccuracyValue = ({ value }: Props) => {
  const valueString = `${value}%`;

  if (value < 33) {
    return (
      <Typography color={DesignTokens.colors.red}>{valueString}</Typography>
    );
  }

  if (value < 66) {
    return (
      <Typography color={DesignTokens.colors.orange}>{valueString}</Typography>
    );
  }

  return (
    <Typography color={DesignTokens.colors.green}>{valueString}</Typography>
  );
};
